import React from 'react';
import styled from 'styled-components';
import { CenteredCircularLoader } from 'components/atoms/loaders';
import { Logo } from 'components/atoms/atoms.styled';
import { NewVerticalList } from 'components/templates';
import { atHomeTheme } from 'global-style';
import Card from '@material-ui/core/Card';
import { BodyText } from 'components/atoms/typography/typography';

function AppLoading(props) {
  return (
    <div className={props.className}>
      {/* <Card className="login-form"> */}
        <NewVerticalList center>
          <Logo />
          <CenteredCircularLoader />
          <BodyText>
            Initializing
          </BodyText>
        </NewVerticalList>
      {/* </Card> */}
    </div>
  );
}

export default styled(AppLoading)`
  min-height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 2rem;
  padding: 20px;

  .login-form {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: ${atHomeTheme.spacing(2)}px;
  }
  .NewLine {
    display: flex;
    margin-bottom: -10px;
  }
  .helpFab {
    margin-top: ${atHomeTheme.spacing(4)}px;
    color: #fff;
  }
  .envelope-icon {
    width: 20px;
    margin-right: ${atHomeTheme.spacing(2)}px;
  }
`;
