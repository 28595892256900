import React from 'react';
import styled from 'styled-components';
import { Route, Switch, useLocation } from 'react-router-dom';
import ActivatePasswordForm from 'components/features/logIn/login-form/activate-password-form';
import AppReturn from 'components/features/logIn/app-return';

const activationRoutes = {
  ACTIVATE: '/activate/:token',
  APP_RETURN: '/activate/app',
};

function Activate(props) {
  const location = useLocation();
  return (
    <div className={props.className}>
      <Switch location={location}>
        <Route path={activationRoutes.APP_RETURN} component={AppReturn} />
        <Route
          exact
          path={activationRoutes.ACTIVATE}
          component={ActivatePasswordForm}
        />
      </Switch>
    </div>
  );
}

export default styled(Activate)`
  min-height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 2rem;
`;
