import React from 'react';
import {
  Switch,
  TextField as MUITextField,
  FormHelperText,
} from '@material-ui/core';
import _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { KeyboardDatePicker } from '@material-ui/pickers';
import MaskedInput from 'react-text-mask';

export const StyledTextField = (props) => (
  <MUITextField margin="normal" size="small" {...props} />
);

export const TextField = (props) => {
  let {
    fieldKey = null,
    initValue = '',
    label,
    multiline = false, // pass multi as true for multi line text input
    onChange,
    required = false,
    errors = {},
    ...rest
  } = props;
  initValue = initValue === null ? '' : initValue;
  const [value, setValue] = React.useState(initValue);
  const onUpdate = (nextValue) => {
    setValue(nextValue);
    onChange(nextValue, fieldKey);
  };

  return (
    <MUITextField
      // margin="normal"
      required={required}
      value={value}
      onChange={({ target: { value } }) => onUpdate(value)}
      label={label}
      multiline={multiline}
      error={errors[fieldKey]}
      size="small"
      name={fieldKey}
      inputProps={{ style: { fontSize: 15 } }}
      {...rest}
    />
  );
};

const PhoneMask = ({ inputRef, ...props }) => {
  return (
    <MaskedInput
      {...props}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2007'}
      showMask
    />
  );
};

export const PhoneTextField = (props) => {
  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }

    const cursorPosition = e.target.selectionEnd;
    if (
      e.target.value.replace(/[^0-9]+/g, '').length === 10 &&
      cursorPosition === 14
    ) {
      e.preventDefault();
    }
  };
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: PhoneMask,
      }}
      onKeyDown={handleKeyDown}
    />
  );
};

export const CheckboxField = (props) => {
  const {
    fieldKey,
    initValue = [],
    label,
    onChange,
    options = [],
    labelPlacement = 'end',
    horizontal = true,
    error = false,
    helperText = '',
  } = props;

  const stringValues = _.isArray(initValue)
    ? initValue.map((v) => '' + v)
    : [initValue];
  const [checked, setChecked] = React.useState(stringValues);
  return (
    <FormControl error={error} margin="normal">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup row={horizontal}>
        {options.map(([optKey, optLabel]) => (
          <FormControlLabel
            key={`radio-input-${optKey}`}
            onChange={({ target: { checked: isChecked } }) => {
              const nextValue = isChecked
                ? [...checked, optKey]
                : checked.filter((opt) => opt !== optKey);
              onChange(nextValue, fieldKey);
              setChecked(nextValue);
            }}
            control={
              <Checkbox
                checked={checked.includes(optKey)}
                name={fieldKey}
                value={optKey}
              />
            }
            label={optLabel}
            labelPlacement={labelPlacement}
          />
        ))}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export const SwitchField = (props) => {
  const { fieldKey, label, onChange, errors = {}, initValue = false } = props;
  const [switchState, setSwitchState] = React.useState(initValue);
  return (
    <FormGroup
      className={props.className}
      error={errors[fieldKey]}
      margin="normal"
      row>
      <FormControlLabel
        label={label}
        control={
          <Switch
            color="primary"
            checked={switchState}
            onChange={() => {
              const nextState = !switchState;
              setSwitchState(nextState);
              onChange(nextState, fieldKey);
            }}
            value={switchState}
          />
        }
      />
    </FormGroup>
  );
};

export function RadioField(props) {
  const {
    initValue = '',
    label,
    onChange,
    options = [],
    required,
    fieldKey,
    horizontal = false,
    fullWidth = false,
    error = false,
    helperText = '',
    disabled = false,
    customStyle = {},
  } = props;
  const [selectedVal, selectNewVal] = React.useState(initValue);
  const style = horizontal ? { justifyContent: 'space-between' } : {};
  return (
    <FormControl
      margin="normal"
      required={required}
      fullWidth={fullWidth}
      style={style}
      error={error}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        row={horizontal}
        value={selectedVal}
        onChange={({ target: { value: nextValue } }) => {
          onChange(nextValue, fieldKey);
          selectNewVal(nextValue);
        }}
        style={
          customStyle && Object.keys(customStyle).length !== 0
            ? customStyle
            : style
        }>
        {options.map(([optionID, optionLabel]) => (
          <FormControlLabel
            key={`radio-field-${optionID}`}
            value={optionID + ''}
            control={<Radio color="secondary" />}
            label={optionLabel}
            labelPlacement="end"
            disabled={disabled}
          />
        ))}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export const DateField = (props) => {
  const {
    fieldKey,
    label,
    required,
    errors = {},
    onChange,
    initValue = null,
    margin = 'normal',
    ...rest
  } = props;
  const [dateValue, setDateValue] = React.useState(initValue);
  return (
    <KeyboardDatePicker
      name={fieldKey}
      autoOk
      disableToolbar
      format="yyyy-MM-dd"
      margin={margin}
      label={label}
      error={errors[fieldKey]}
      required={required}
      value={dateValue}
      variant="inline"
      onChange={(date, value) => {
        setDateValue(date);
        onChange(fieldKey, value); // TODO, switch arguments to follow sig of other fields, refactor where its used
      }}
      {...rest}
    />
  );
};
