import React from 'react';
import { useDispatch } from 'react-redux';
import Table from 'components/molecules/cruddy-table/cruddy-table';
import { TableBodyText } from '../../atoms/typography/typography';
import { StyledAvatar } from 'components/atoms/atoms.styled';
import { Delete, LockOpen } from '@material-ui/icons';
import PatientCaregiverTable from 'components/organisms/patient-caregiver-table';
import config from 'config';
import { useHistory } from 'react-router-dom';
import { activatePatient, deletePatient } from 'redux/actions/patient_actions';
import { enqueueSnack } from 'redux/modules/ui';
import _ from 'lodash';

export default ({ patients, editAction, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const columns = [
    {
      title: '',
      field: 'photo',
      render: (patient) => (
        <StyledAvatar
          src={`${config.apiUrl}/image/${patient.photo}`}
          alt={patient.fullName}
        />
      ),
      width: 100,
      filtering: false,
      export: false,
    },
    {
      title: 'Name',
      field: 'fullName',
      render: (patient) => <TableBodyText>{patient.fullName}</TableBodyText>,
    },
    {
      title: 'DOB',
      field: 'dateOfBirth',
      render: (patient) => <TableBodyText>{patient.dateOfBirth}</TableBodyText>,
    },
    {
      title: 'Address',
      field: 'address',
      render: (patient) => (
        <TableBodyText>{`${patient.address}, ${patient.city}, ${patient.state}, ${patient.zip}`}</TableBodyText>
      ),
    },
    {
      title: 'Status',
      field: 'status',
      render: (patient) => (
        <TableBodyText>
          {_.startCase(_.toLower(patient.status).replace('_', ' '))}
        </TableBodyText>
      ),
      lookup: {
        ACTIVE: 'Active',
        DELETED: 'Deleted',
      },
    },
  ];

  const disablePatient = async (patient) => {
    if (!patient || !patient.id) {
      return;
    }

    const updatedPatient = await dispatch(deletePatient(patient.id));

    if (
      updatedPatient &&
      updatedPatient.id &&
      updatedPatient.status === 'DELETED'
    ) {
      dispatch(
        enqueueSnack({
          message: 'Patient Deleted',
          variant: 'success',
          timeout: 2500,
        })
      );
    }
  };

  const enablePatient = async (patient) => {
    if (!patient || !patient.id) {
      return;
    }

    const updatedPatient = await dispatch(activatePatient(patient.id));

    if (
      updatedPatient &&
      updatedPatient.id &&
      updatedPatient.status === 'ACTIVE'
    ) {
      dispatch(
        enqueueSnack({
          message: 'Patient Activated',
          variant: 'success',
          timeout: 2500,
        })
      );
    }
  };

  return (
    <>
      <Table
        style={{ boxShadow: 'none' }}
        data={patients}
        cols={columns}
        opts={{
          toolbar: true,
          showTitle: false,
          paging: true,
          search: true,
          paginationType: 'stepped',
          pageSizeOptions: [10, 20, 50, 100, 200, 500],
          pageSize: 20,
          actionsColumnIndex: -1,
          filtering: true,
          exportButton: true,
          exportFileName: 'Patient Export',
        }}
        localization={{
          body: { emptyDataSourceMessage: 'No patients found' },
        }}
        detailPanel={[
          {
            tooltip: 'Caregivers',
            render: (rowData) => {
              rowData.primaryCaregiver.primaryCaregiver = true;
              return (
                <PatientCaregiverTable
                  caregivers={[rowData.primaryCaregiver, ...rowData.caregivers]}
                  patient={rowData}
                />
              );
            },
          },
        ]}
        actions={[
          (rowData) => ({
            icon: rowData.status === 'ACTIVE' ? Delete : LockOpen,
            tooltip: rowData.status === 'ACTIVE' ? 'Disable' : 'Enable',
            onClick: (event, rowData) =>
              rowData.status === 'ACTIVE'
                ? disablePatient(rowData)
                : enablePatient(rowData),
          }),
        ]}
        onRowClick={(event, rowData, togglePanel) =>
          history.push(`/dashboard/admin/patient/${rowData.id}`)
        }
      />
    </>
  );
};
