import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import UserTable from 'components/organisms/user-table';
import { getAdminUsers } from 'redux/actions/admin_actions';
import { TableSkeleton } from 'components/atoms/loaders';
import { HeaderText } from 'components/atoms/typography/typography';
import { openGlobalModal } from 'redux/modules/ui';
import AdminForm from 'components/molecules/admin-form/admin-form';
import { Button } from '@material-ui/core';
import { GroupAdd } from '@material-ui/icons';
import { AdminHeader } from 'components/atoms/atoms.styled';
import { atHomeTheme } from "../../../global-style";

const AdminList = ({ ...props }) => {
  const users = useSelector(state => state.admin.users);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const fetchUsers = async () => {
    setLoading(true);
    await dispatch(getAdminUsers());
    setLoading(false);
  }

  const openAddUserModal = () => {
    const modalProps = {
      fullWidth: true,
      title: 'Create Admin'
    };
    dispatch(
      openGlobalModal(<AdminForm />, modalProps)
    );
  }

  const openEditUserModal = (user) => {
    const modalProps = {
      fullWidth: true,
      title: 'Edit Admin'
    };
    dispatch(
      openGlobalModal(
        <AdminForm admin={user} />,
        modalProps
      )
    );
  }

  React.useEffect(() => {
    fetchUsers()
  }, []);

  return (
    <>
      <AdminHeader>
        <HeaderText>Administrators</HeaderText>
        <Button
          startIcon={<GroupAdd />}
          onClick={openAddUserModal}
        >
          Create Admin
        </Button>
      </AdminHeader>
      {loading ? (
        <TableSkeleton />
      ) : (
        <UserTable users={users} editAction={openEditUserModal} />
      )}
    </>
  );
}

export default styled(AdminList)`
  && span.MuiButtonBase-root.MuiTableSortLabel-root {
    color: ${atHomeTheme.palette.primary.main} !important;
    font-weight: bold;
    font-size: 1.15rem !important;
  }
  && td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft {
    font-size: 14px !important;
  }
`;
