
const devConfig = {
  apiUrl: 'https://dev2.nobleapplications.com/at-home-dev/api',
  routerBasename: '/at-home-dev',
};

const prodConfig = {
  apiUrl: 'https://athome.pediatrics.wisc.edu/api',
  routerBasename: '/'
};

var config = devConfig;

if (process.env.REACT_APP_ENV === 'prod') {
  config = prodConfig;
}

export default config;