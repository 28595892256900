import React from 'react';
import styled from 'styled-components';
import { Route, Switch, useLocation } from 'react-router-dom';
import PublicSignupForm from 'components/features/public-signup/public-signup-form';
import InitialVisit from 'components/initial-visit';
import ProtectedRoute from 'components/protected-route';

const signupRoutes = {
  SIGNUP: '/signup',
  WELCOME: '/signup/welcome'
};

function Activate(props) {
  const location = useLocation();
  
  return (
    <div className={props.className}>
      <Switch location={location}>
        <Route exact path={signupRoutes.SIGNUP} component={PublicSignupForm} />
        <ProtectedRoute exact path={signupRoutes.WELCOME} component={InitialVisit} />
      </Switch>
    </div>
  );
}

export default styled(Activate)`
  min-height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 2rem;
`;
