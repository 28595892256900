import React from 'react';
import styled from 'styled-components';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import LoginForm from 'components/features/logIn/login-form/login-form';
import ForgotPasswordForm from 'components/features/logIn/login-form/forgot-password-form';
import ResetPasswordForm from 'components/features/logIn/login-form/reset-password-form';
import ResetSuccessful from 'components/features/logIn/reset-successful';

const loginRoutes = {
  LOGIN: '/login',
  REGISTER: '/activate/:activationid',
  FORGOT: '/login/forgot',
  RESET: '/login/password-reset/:passwordRequestToken',
  SUCCSSS: '/login/reset-password-success',
};

function Login(props) {
  const location = useLocation();
  return (
    <div className={props.className}>
      <Switch location={location}>
        <Route exact path={loginRoutes.LOGIN} component={LoginForm} />
        <Route exact path={loginRoutes.FORGOT} component={ForgotPasswordForm} />
        <Route exact path={loginRoutes.RESET} component={ResetPasswordForm} />
        <Route exact path={loginRoutes.SUCCSSS} component={ResetSuccessful} />
        <Redirect to={loginRoutes.LOGIN} />
      </Switch>
    </div>
  );
}

export default styled(Login)`
  min-height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 2rem;
`;
