import { createReducer } from 'redux/reducer-utils';

export const SET_ROUTINES = 'SET_ROUTINES';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';

const defaultStartDate = new Date();
defaultStartDate.setDate(new Date().getDate() - 1);

const defaultState = {
  routines: [],
  startDate: defaultStartDate,
  endDate: new Date(),
};

export default createReducer((defaultState: defaultState), {
  [SET_ROUTINES]: (state, { routines = []}) => {
    return {
      ...state,
      routines
    }
  },
  [SET_START_DATE]: (state, { startDate = defaultStartDate }) => {
    return {
      ...state,
      startDate
    }
  },
  [SET_END_DATE]: (state, { endDate = new Date() }) => {
    return {
      ...state,
      endDate,
    }
  }
});

export const setStartDate = startDate => ({
  type: SET_START_DATE,
  startDate,
});

export const setEndDate = endDate => ({
  type: SET_END_DATE,
  endDate,
});