import React from 'react';
import styled from 'styled-components';
import { Route, Switch, useLocation } from 'react-router-dom';
import ResetPasswordForm from 'components/features/logIn/login-form/reset-password-form';

const passwordRoutes = {
  RESET: '/pwreset/:passwordRequestToken',
};

function Password(props) {
  const location = useLocation();
  return (
    <div className={props.className}>
      <Switch location={location}>
      <Route exact path={passwordRoutes.RESET} component={ResetPasswordForm} />
      </Switch>
    </div>
  );
}

export default styled(Password)`
  min-height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 2rem;
`;
