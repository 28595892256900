import React from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Logo } from './atoms/atoms.styled';
import { BodyText, HeaderText } from 'components/atoms/typography/typography';
import { NewVerticalList } from './templates';
import { Card, Button } from '@material-ui/core';
import AppleLogo from 'assets/apple.svg';
import { ExitToApp } from '@material-ui/icons';
import { logOut } from 'redux/actions/auth_actions';

export default ({ ...props }) => {
  const patients = useSelector((state) => state.patient.patients);
  const self = useSelector((state) => state.self);
  const isPrimaryCaregiver =
    self.authorities.filter((a) => a.role === 'ROLE_PRIMARY_CAREGIVER').length >
    0;
  const dispatch = useDispatch();

  if (isPrimaryCaregiver && patients.length) {
    return <Redirect to="/dashboard" />;
  }

  const getWelcomeMessage = () => {
    if (isPrimaryCaregiver) {
      return `
        Get started by adding your first patient. After your patient 
        is created you can begin adding routines, and invite other members 
        to your patient care network.
      `;
    } else {
      return `
        Get started by downloading the iOS app from the App Store and logging 
        in using your email address and password.
      `;
    }
  };

  return (
    <ContainerDiv>
      <FixedLogoutButton
        color="primary"
        startIcon={<ExitToApp />}
        onClick={() => dispatch(logOut())}>
        Sign Out
      </FixedLogoutButton>
      <CardContainer>
        <NewVerticalList center className={props.classes}>
          <Logo />
          <HeaderText>Welcome to @Home</HeaderText>
          <StyledBodyText>{getWelcomeMessage()}</StyledBodyText>
          <a href="https://apple.com">
            <img alt="Download @Home" src={AppleLogo} />
          </a>
        </NewVerticalList>
      </CardContainer>
    </ContainerDiv>
  );
};

const FixedLogoutButton = styled(Button)`
  position: fixed !important;
  top: 2rem;
  right: 2rem;
`;

const ContainerDiv = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardContainer = styled(Card)`
  max-width: 700px;
  padding: 1rem 0;
`;

const StyledBodyText = styled(BodyText)`
  padding: 24px;
  text-align: center;
`;
