// @flow
import { createReducer } from 'redux/reducer-utils';
import type { PatientResponse } from 'data-flows';
import _ from 'lodash';

export const SET_CAREGIVER_PATIENT = 'SET_CAREGIVER_PATIENT';
export const SET_PATIENTS = 'SET_PATIENTS';
export const SET_PATIENT = 'SET_PATIENT';
export const PATIENT_ADDED = 'PATIENT_ADDED';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const SUPLANT_PATIENT = 'SWITCHING_PATIENT';
export const UPDATE_MY_PATIENTS = ' UPDATE_MY_PATIENTS';
export const SET_CAREGIVERS = 'SET_CAREGIVERS';
export const SET_ROUTINES = 'SET_ROUTINES';
export const SET_CARE_ROUTINES = 'SET_CARE_ROUTINES';
export const SET_NUTRITION = 'SET_NUTRITION';
export const SET_MEDICATION = 'SET_MEDICATION';
export const SET_SUPPLIES = 'SET_SUPPLIES';
export const SET_TASKS = 'SET_TASKS';
export const SET_CARE_ROUTES = 'SET_CARE_ROUTES';
export const SET_MEASUREMENT_UNITS = 'SET_MEASUREMENT_UNITS';
export const APPEND_CARE = 'APPEND_CARE';

const defaultState = {
  patient: {},
  patients: [],
  allCaregivers: [],
  routines: [],
  careRoutines: [],
  nutrition: [],
  medication: [],
  supplies: [],
  tasks: [],
  careRoutes: [],
  measurementUnits: [],
};

export default createReducer((defaultState: {}), {
  [SET_PATIENT]: (state, { patient = [] }) => {
    return {
      ...state,
      patient,
    };
  },
  [SET_CAREGIVER_PATIENT]: (state, { patients = [] }) => {
    const currentPatientId = parseInt(sessionStorage.getItem('currentPatient'));
    let selectedPatient = patients[0];

    if (currentPatientId) {
      selectedPatient =
        patients.find((p) => p.id === currentPatientId) || selectedPatient;
    }

    sessionStorage.setItem('currentPatient', selectedPatient.id);

    return {
      ...state,
      patient: selectedPatient,
    };
  },
  [SET_PATIENTS]: (state, { patients = [] }) => {
    return {
      ...state,
      patients,
    };
  },
  [PATIENT_ADDED]: (state, patient) => {
    const patients = [...state.patients, patient.patient];
    return {
      ...state,
      patients,
    };
  },
  [UPDATE_PATIENT]: (state, patient) => {
    let patients = [...state.patients];
    const patientIndex = _.findIndex(patients, { id: patient.patient.id });

    if (patientIndex >= 0) {
      patients.splice(patientIndex, 1, patient.patient);
    }

    return {
      ...state,
      ...patient,
      patients,
    };
  },
  [SUPLANT_PATIENT]: (state, patient) => {
    sessionStorage.setItem('currentPatient', patient.patient.id);
    return {
      ...state,
      patient: patient.patient,
    };
  },
  [UPDATE_MY_PATIENTS]: (state, patients) => {
    let patient = patients.patients[0];
    const currentPatientId = parseInt(sessionStorage.getItem('currentPatient'));
    if (currentPatientId) {
      patient =
        patients.patients.find((p) => p.id === currentPatientId) ||
        state.patient;
    }

    return {
      ...state,
      patients: patients.patients,
      patient,
    };
  },
  [SET_CAREGIVERS]: (state, payload) => {
    return {
      ...state,
      allCaregivers: payload.data,
    };
  },
  [SET_ROUTINES]: (state, routines) => {
    return {
      ...state,
      ...routines,
    };
  },
  [SET_CARE_ROUTINES]: (state, careRoutines) => {
    return {
      ...state,
      ...careRoutines,
    };
  },
  [SET_NUTRITION]: (state, nutrition) => {
    return {
      ...state,
      ...nutrition,
    };
  },
  [SET_MEDICATION]: (state, medication) => {
    return {
      ...state,
      ...medication,
    };
  },
  [SET_SUPPLIES]: (state, supplies) => {
    return {
      ...state,
      ...supplies,
    };
  },
  [SET_TASKS]: (state, tasks) => {
    return {
      ...state,
      ...tasks,
    };
  },
  [SET_CARE_ROUTES]: (state, careRoutes) => {
    return {
      ...state,
      ...careRoutes,
    };
  },
  [SET_MEASUREMENT_UNITS]: (state, measurementUnits) => {
    return {
      ...state,
      ...measurementUnits,
    };
  },
  [APPEND_CARE]: (state, care) => {
    return {
      ...state,
      careRoutines: [...state.careRoutines, care.careRoutine],
    };
  },
});

// synchronous action creators
export const setPatient = (patient: PatientResponse) => {
  return {
    type: SET_PATIENT,
    patient,
  };
};

export const setPatients = (patients) => ({
  type: SET_PATIENTS,
  patients,
});

export const patientAdded = (patient) => ({
  type: PATIENT_ADDED,
  patient,
});

export const patientUpdate = (patient) => ({
  type: UPDATE_PATIENT,
  patient,
});

export const setRoutines = (routines) => ({
  type: SET_ROUTINES,
  routines,
});

export const setCareRoutines = (careRoutines) => ({
  type: SET_CARE_ROUTINES,
  careRoutines,
});

export const setNutrition = (nutrition) => ({
  type: SET_NUTRITION,
  nutrition,
});

export const setMedication = (medication) => ({
  type: SET_MEDICATION,
  medication,
});

export const setSupplies = (supplies) => ({
  type: SET_SUPPLIES,
  supplies,
});

export const setTasks = (tasks) => ({
  type: SET_TASKS,
  tasks,
});

export const setCareRoutes = (careRoutes) => ({
  type: SET_CARE_ROUTES,
  careRoutes,
});

export const setMeasurementUnits = (measurementUnits) => ({
  type: SET_MEASUREMENT_UNITS,
  measurementUnits,
});

export const appendCare = (careRoutine) => ({
  type: APPEND_CARE,
  careRoutine,
});
