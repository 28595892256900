import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
import AppRouter from "./app-router";
import 'react-widgets/dist/css/react-widgets.css';
import './app.css'

ReactDOM.render(
  <App>
    <AppRouter />
  </App>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
