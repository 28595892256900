import React from 'react';
import Table from 'components/molecules/cruddy-table/cruddy-table';
import { TableBodyText } from '../../atoms/typography/typography';
import { StyledAvatar } from 'components/atoms/atoms.styled';
import config from 'config';

export default ({ patient, caregivers, editAction, ...props }) => {
  const columns = [
    {
      title: '',
      field: 'photo',
      render: (caregiver) => (
        <StyledAvatar
          src={`${config.apiUrl}/image/${caregiver.photo}`}
          alt={caregiver.fullName}
        />
      ),
      width: 100,
    },
    {
      title: 'Name',
      field: 'fullName',
      render: (caregiver) => (
        <TableBodyText>{caregiver.fullName}</TableBodyText>
      ),
    },
    {
      title: 'Email',
      field: 'username',
      render: (caregiver) => (
        <TableBodyText>{caregiver.username}</TableBodyText>
      ),
    },
    {
      title: 'Phone',
      field: 'phone',
      render: (caregiver) => <TableBodyText>{caregiver.phone}</TableBodyText>,
    },
    {
      title: 'Relationship',
      field: 'relationship',
      render: (caregiver) => (
        <TableBodyText>
          {caregiver.primaryCaregiver
            ? 'Primary Caregiver'
            : caregiver.relationship}
        </TableBodyText>
      ),
    },
    {
      title: 'Primary Caregiver',
      field: 'isPrimary',
      render: (caregiver) => (
        <TableBodyText>
          {caregiver.primaryCaregiver ? 'Primary' : 'Secondary'}
        </TableBodyText>
      ),
    },
  ];

  return (
    <Table
      title="Caregivers"
      style={{ boxShadow: 'none', padding: '2rem', backgroundColor: '#EEE' }}
      data={caregivers}
      cols={columns}
      opts={{
        header: false,
        toolbar: true,
        showTitle: true,
        paging: false,
        size: 'small',
        rowStyle: {
          backgroundColor: '#EEE',
        },
      }}
      localization={{
        body: { emptyDataSourceMessage: 'No caregivers found' },
        header: {
          actions: '',
        },
      }}
    />
  );
};
