import React from 'react';
import { Redirect } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { SubmitButton } from 'components/atoms/buttons';
import { CenteredCircularLoader } from 'components/atoms/loaders';
import { Logo, PrimaryHelpLink } from 'components/atoms/atoms.styled';
import { useDispatch, useSelector } from 'react-redux';
import { NewVerticalList } from 'components/templates';
import { atHomeTheme } from 'global-style';
import Card from '@material-ui/core/Card';
import { BodyText, HeaderText } from 'components/atoms/typography/typography';
import { StyledTextField } from 'components/atoms/fields/index';
import { publicSignup } from 'redux/actions/auth_actions';
import {useHistory} from 'react-router-dom';
import { PhoneTextField } from 'components/atoms/fields';

function PublicSignupForm(props) {
  const [loading, setLoading] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const self = useSelector(state => state.self);
  const dispatch = useDispatch();
  const { errors, handleSubmit, control } = useForm();
  const history = useHistory();
  const fieldProps = {
    as: StyledTextField,
    control,
    defaultValue: '',
    fullWidth: true,
  };

  const signup = async values => {
    const payload = {
      ...values,
      communicationMethod: 'EMAIL',
      relationship: 'Primary Caregiver',
    };
    setLoading(true);
    const caregiver = await dispatch(publicSignup(payload))
    setLoading(false);

    if (caregiver) {
      setShowSuccess(true);
    }
  };

  const sendToLogin = () => {
    history.push('/')
  }

  if (self && self.id) {
    return <Redirect to="/login" />
  }

  return (
    <Card style={{ minWidth: '400px' }}>
      <NewVerticalList className={props.className} center>
        <Logo />
        {showSuccess ? (
          <>
            <HeaderText>Welcome to @Home!</HeaderText>
            <BodyText>
              Check your email for a link to set your password 
              and activate your account. After your account is activated you can 
              start building your patient information and inviting other caregivers 
              to your care network
            </BodyText>
          </>
        ) : (
          <form onSubmit={handleSubmit(signup)} className={'login-form'}>
            <BodyText style={{ textAlign: 'center', fontWeight: 600 }}>
              Signup as a primary caregiver
            </BodyText>
            <Controller
              {...fieldProps}
              name="username"
              label="Email Address"
              variant="outlined"
              error={errors.username ? true : false}
              helperText={errors.username ? errors.username.message : ''}
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'A valid email is required',
                }
              }}
            />
            <Controller
              {...fieldProps}
              type="text"
              name="firstName"
              label="First Name"
              variant="outlined"
              error={errors.firstName ? true : false}
              helperText={errors.firstName ? errors.firstName.message : ''}
              rules={{ required: 'First name is required' }}
            />
            <Controller
              {...fieldProps}
              type="text"
              name="lastName"
              label="Last Name"
              variant="outlined"
              error={errors.lastName ? true : false}
              helperText={errors.lastName ? errors.lastName.message : ''}
              rules={{ required: 'Last name is required' }}
            />
            <Controller
              {...fieldProps}
              as={PhoneTextField}
              name="phone"
              label="Phone Number"
              variant="outlined"
              error={errors.phone ? true : false}
              helperText={errors.phone ? errors.phone.message : ''}
              rules={{
                required: 'Phone is required',
                pattern: {
                  value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
                  message: 'A valid phone number is required',
                }
              }}
            />
            {loading ? (
              <CenteredCircularLoader />
            ) : (
              <SubmitButton fullWidth>Register</SubmitButton>
            )}
          </form>
        )}
        <BodyText>
          Already have an account?
        </BodyText>
        <BodyText>
          <PrimaryHelpLink onClick={sendToLogin}>
            {showSuccess ? ' Home' : 'Sign In'}
          </PrimaryHelpLink>
        </BodyText>
      </NewVerticalList>
    </Card>
  );
}

export default styled(PublicSignupForm)`
  max-width: 400px;
  min-width: 100%;
  padding: 20px;
  .login-form {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: ${atHomeTheme.spacing(2)}px;
  }
  .NewLine {
    display: flex;
    margin-bottom: -10px;
  }
  .helpFab {
    margin-top: ${atHomeTheme.spacing(4)}px;
    color: #fff;
  }
  .envelope-icon {
    width: 20px;
    margin-right: ${atHomeTheme.spacing(2)}px;
  }
`;
