// @flow
import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import styled from "styled-components";

type NavTabsProps = {
  tabs: Array<{
    label: string,
    href: string,
    content: any
  }>
};

function NavTabs(props: NavTabsProps) {
  const { tabs = [] } = props;
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={props.className}>
      <AppBar position="static" color="primary" type="dark">
        <Tabs variant="fullWidth" value={value} onChange={handleChange}>
          {tabs.map(({ label, href }) => {
            return (
              <LinkTab label={label} href={href} key={`nav-tab-${label}`} />
            );
          })}
        </Tabs>
      </AppBar>
      {tabs.map(({ content }, index) => {
        return (
          <TabPanel value={value} index={index} key={`tab-panel-${index}`}>
            {content}
          </TabPanel>
        );
      })}
    </div>
  );
}

export default styled(NavTabs)``;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}
