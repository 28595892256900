import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { format, fromUnixTime } from 'date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { setStartDate, setEndDate } from 'redux/modules/report';


export default ({ ...props }) => {
  const dispatch = useDispatch();
  const startDate = useSelector(state => state.report.startDate);
  const endDate = useSelector(state => state.report.endDate);

  const handleDateChange = (name, date) => {
    if (name === 'startDate') {
      dispatch(setStartDate(date));
    } else {
      dispatch(setEndDate(date));
    }
  }
  
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateFieldWrapper>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Start Date"
          value={startDate}
          onChange={date => handleDateChange('startDate', date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </DateFieldWrapper>
      <DateFieldWrapper>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="End Date"
          value={endDate}
          onChange={date => handleDateChange('endDate', date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </DateFieldWrapper>
    </MuiPickersUtilsProvider>
  )
}

const DateFieldWrapper = styled('div')`
  padding: 1rem;
`;