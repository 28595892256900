import React from 'react';
import styled from 'styled-components';
import { ProfilePicWrapper } from '../../templates';
import { atHomeTheme } from '../../../global-style';
import config from 'config';

const GeneralProfile = (props) => {
  const {
    currentCaregiver,
    btnTxt,
    btnClick,
    className,
    primaryCaregiver,
  } = props;
  const isPrimary = currentCaregiver.authorities
    ? currentCaregiver.authorities.filter(
        (a) => a.role === 'ROLE_PRIMARY_CAREGIVER'
      ).length > 0
    : false;

  return (
    <div className={className}>
      <div className="profile-card">
        <div className="profile-card__head">
          <div className="profile-card__profile">
            <ProfilePicWrapper
              src={`${config.apiUrl}/image/${currentCaregiver?.photo}`}
              height={atHomeTheme.spacing(8)}
              style={{ justifySelf: 'start' }}
            />
            <div>
              <h3>{currentCaregiver.fullName}</h3>
              <span>{currentCaregiver.username}</span>
            </div>
          </div>
          <button className="edit" onClick={btnClick}>
            {btnTxt}
          </button>
        </div>
        <div className="grid-2">
          <div className="data-ele">
            <label htmlFor="">Phone</label>
            <span>{currentCaregiver.phone}</span>
          </div>
          <div className="data-ele">
            <label htmlFor="">Communication method</label>
            <span>{currentCaregiver?.communicationMethod.toLowerCase()}</span>
          </div>
          <div className="data-ele">
            <label htmlFor="">Relationship to patient</label>
            <span>
              {isPrimary || primaryCaregiver
                ? 'Primary Caregiver'
                : currentCaregiver?.relationship}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default styled(GeneralProfile)`
  margin-top: 1rem;
  .edit {
    display: block;
    color: #d33939;
    align-self: center;
    justify-self: end;
    max-width: 150px;
    background-color: transparent;
    border: none;
    margin: 1rem;
    &:hover {
      cursor: pointer;
    }
  }
  .data-ele {
    padding: 1rem;
    label {
      font-size: 0.875rem;
      color: hsl(210, 3%, 42.7%);
      display: block;
      padding: 0.5rem 0;
    }
    span {
      font-size: 0.875rem;
      color: hsl(210, 7.5%, 21%);
      text-transform: capitalize;
    }
  }
  .settings-head {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-content: center;
    justify-content: end;
  }
  .grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 1rem;
    margin: 0 !important;
  }
  .profile-card {
    border-radius: 0.25rem;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
    &__head {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      border-bottom: solid 1px hsl(210, 3%, 93.5%);
    }
    &__profile {
      padding: 1rem;
      border-bottom: solid 1px hsl(210, 3%, 93.5%);
      display: grid;
      grid-template-columns: 60px 1fr;
      h3 {
        font-size: 1rem;
        color: hsl(20, 7.5%, 21%);
        text-transform: capitalize;
        margin-bottom: 0.5rem;
      }
      span {
        font-size: 0.875rem;
        color: hsl(210, 3%, 42.7%);
      }
    }
  }
`;
