import { api } from '../../api';
import { withErrorHandling } from '../action-utils';
import { setPatients } from 'redux/modules/patient';
import {
  setAdminUsers,
  setCaregivers,
  adminUpdated,
  adminCreated,
} from 'redux/modules/admin';

export const getAdminPatients = () =>
  withErrorHandling(async (dispatch) => {
    const { data: patients } = await api.get('/patients');
    // dispatch(setPatient(patients));s
    dispatch(setPatients(patients));
  });

export const getAdminUsers = () =>
  withErrorHandling(async (dispatch) => {
    const { data: users } = await api.get('/admins');
    dispatch(setAdminUsers(users));
  });

export const getCaregivers = () =>
  withErrorHandling(async (dispatch) => {
    const { data: caregivers } = await api.get('/caregivers');
    dispatch(setCaregivers(caregivers));
  });

export const saveAdmin = (payload) => (dispatch) => {
  if (payload.id) {
    return dispatch(updateAdmin(payload));
  } else {
    return dispatch(createAdmin(payload));
  }
};

export const updateAdmin = (payload) =>
  withErrorHandling(async (dispatch) => {
    const { data: admin } = await api.put('/admins', payload);
    dispatch(adminUpdated(admin));
    return admin;
  });

export const createAdmin = (payload) =>
  withErrorHandling(async (dispatch) => {
    const { data: admin } = await api.post('/admins', payload);
    dispatch(adminCreated(admin));
    return admin;
  });

export const deleteAdmin = (adminId) =>
  withErrorHandling(async (dispatch) => {
    const { data: admin } = await api.delete(`/users/${adminId}`);
    dispatch(adminUpdated(admin));
    return admin;
  });

export const activateAdmin = (adminId) =>
  withErrorHandling(async (dispatch) => {
    const { data: admin } = await api.put(`/users/${adminId}/activate`);
    dispatch(adminUpdated(admin));
    return admin;
  });
