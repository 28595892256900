import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const self = useSelector(state => state.self);
  return (
    <Route {...rest} render={(props) => (
      self.id > 0
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
  );
}

export default ProtectedRoute;