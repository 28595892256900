import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import NavTabs from 'components/molecules/nav-tabs/nav-tabs';
import { useDispatch, useSelector } from 'react-redux';
import ChildProfile from 'components/molecules/child-profile/index';
import { selectPatient } from 'redux/reselects';
import {
  getPatientById,
  getPatientInventory,
} from 'redux/actions/patient_actions';
import { CenteredCircularLoader } from 'components/atoms/loaders';
import Report from '../../components/organisms/reports/routine-report';
import TaskTable from 'components/molecules/reports/task-table';
import CareRoutineTable from 'components/molecules/reports/care-table';

function ReportView(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const patient = useSelector(selectPatient);
  const [loading, setLoading] = React.useState(false);
  const childTabs = React.useMemo(
    () => [
      {
        label: 'Routines',
        href: '/dashboard/report',
        content: <Report />,
      },
      {
        label: 'Meds/Food',
        href: '/dashboard/child/care-givers',
        content: <CareRoutineTable />,
      },
      {
        label: 'Tasks',
        href: '/dashboard/report/tasks',
        content: <TaskTable />,
      },
    ],
    [patient]
  );

  const fetchPatient = async () => {
    const { patientId } = params;

    if (patientId) {
      setLoading(true);
      await dispatch(getPatientById(patientId));
      await dispatch(getPatientInventory(patientId));
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchPatient();
  }, []);

  if (loading) {
    return <CenteredCircularLoader />;
  }

  return (
    <section className={props.className}>
      <ChildProfile />
      <NavTabs centered tabs={childTabs} />
    </section>
  );
}

export default styled(ReportView)`
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 12px 12px 12px;
  }
  .end-btn {
    align-self: center;
    justify-self: end;
    background-color: transparent !important;
    color: #d33939 !important;
    font-size: 14px !important;
    svg {
      margin-left: 8px;
    }
  }
`;
