import React from 'react';
import { Dialog, DialogTitle, Divider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_GLOBAL_MODAL } from 'redux/modules/ui';
import { HeaderText } from 'components/atoms/typography/typography';

export default function GlobalModal() {
  const dispatch = useDispatch();
  const { isOpen, content, modalProps } = useSelector(state => state.ui.modal);
  return React.useMemo(() => {
    return (
      <Dialog
        open={isOpen}
        onBackdropClick={() => dispatch({ type: CLOSE_GLOBAL_MODAL })}
        {...modalProps}>
        {modalProps.title && 
          <DialogTitle>
            <HeaderText>{modalProps.title}</HeaderText>
            <Divider />
          </DialogTitle>
        }
        <>{content}</>
      </Dialog>
    );
  }, [isOpen, dispatch, content, modalProps]);
}
