import { enqueueSnack } from 'redux/modules/ui';

export const withErrorHandling = (
  thunkFn,
  errorHandler = defaultErrorHandler
) => {
  return async (dispatch, getState) => {
    try {
      const returnVal = await thunkFn(dispatch, getState);
      return returnVal || true;
    } catch (error) {
      return errorHandler(dispatch, getState, error);
    }
  };
};

const defaultErrorHandler = (dispatch, _getState, error) => {
  try {
    let {
      response: {
        data: { message },
      },
    } = error;

    if (!message) {
      message = 'An unknown error occurred';
    }

    dispatch(
      enqueueSnack({
        message,
        variant: 'error',
        timeout: 2500,
      })
    );
  } catch (e) {
    //
  }

  console.error('action error', error);
  return false;
};
