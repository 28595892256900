// @flow
import tableIcons from "./table-icons";
import MaterialTable from "material-table";
import React from "react";

const defaultOpts = {
  actionsColumnIndex: -1,
  debounceInterval: 777,
  doubleHorizontalScroll: false,
  emptyRowsWhenPaging: false,
  filtering: false, // disable on a column-by-column basis
  maxBodyHeight: "max-content",
  padding: "default",
  pageSize: 5,
  pageSizeOptions: [5, 10, 20],
  paging: true,
  search: false,
  showTitle: false,
  sorting: true, // disable sorting feature on col-config if needed
  toolbar: false,
  rowStyle: ({ tableData: { id } }) => {
    const backgroundColor = id % 2 === 0 ? "#FFFFFF" : "#F8F8F8";
    return { backgroundColor, height: "auto" };
  }
};

export default function CruddyTable(props: {
  actions?: Array<{
    disabled?: boolean,
    icon: any,
    onClick: Function,
    tooltip?: string
  }>,
  opts?: {},
  cols: Array<{
    title: string,
    field: string
  }>,
  data: Array<any>,
  isLoading?: boolean
}) {
  const { actions = [], opts = {}, cols, data, isLoading, ...rest } = props;
  return React.useMemo(
    () => (
      <MaterialTable
        actions={actions}
        columns={cols}
        data={data}
        icons={tableIcons}
        isLoading={isLoading}
        options={{ ...defaultOpts, ...opts }}
        {...rest}
      />
    ),
    [data, isLoading, cols, actions, opts, rest]
  );
}
