import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { HeaderText } from 'components/atoms/typography/typography';
import GeneralProfile from '../../molecules/care-giver-profile/profile-card';

function ChildNetworkList(props) {
  const caregivers = useSelector((state) => state.patient.patient.caregivers);
  const patient = useSelector((state) => state.patient.patient);

  return (
    <section style={{ padding: '1rem 3rem' }}>
      <HeaderText>Caregiver Network</HeaderText>
      <div className={props.className} style={{ marginTop: '1rem' }}>
        <div className="cont">
          <GeneralProfile
            currentCaregiver={patient.primaryCaregiver}
            primaryCaregiver
          />
          {caregivers.length > 0 &&
            caregivers.map((caregiver, idx) => {
              return <GeneralProfile key={idx} currentCaregiver={caregiver} />;
            })}
        </div>
      </div>
    </section>
  );
}

export default styled(ChildNetworkList)`
  .cont {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
  }
  .skeleton {
    background-color: transparent !important;
    color: #d33939 !important;
    font-size: 14px !important;
    svg {
      margin-left: 8px;
    }
  }
`;
