import { api, loginReq, setAuthHeader, getEndUserData } from '../../api';
import { SET_SELF, LOGOUT } from '../modules/self';
import { withErrorHandling } from '../action-utils';

export const setLoginFlow = (payload) =>
  withErrorHandling(async (dispatch) => {
    const {
      data: { token },
    } = await loginReq(payload);
    setAuthHeader(token);
    return dispatch(getSelf());
  });

export const getSelf = () =>
  withErrorHandling(
    async (dispatch) => {
      const { data: user } = await getEndUserData();
      dispatch({
        type: SET_SELF,
        user,
      });
      return user;
    },
    () => false
  );

export const forgotPassword = (payload) =>
  withErrorHandling(async (dispatch) => {
    const { data: success } = await api.post('/users/forgotpw', payload);
    return success;
  });

export const logOut = () => ({
  type: LOGOUT,
});

export const activate = (payload) =>
  withErrorHandling(async (dispatch) => {
    const { data: token } = await api.post('/users/activate', payload);
    setAuthHeader(token.token);
    const user = await dispatch(getSelf());
    return user;
  });

export const publicSignup = (payload) =>
  withErrorHandling(async (dispatch) => {
    const { data: caregiver } = await api.post('/caregivers/primary', payload);
    return caregiver;
  });

export const resetPassword = (payload) =>
  withErrorHandling(async (dispatch) => {
    await api.post('/users/resetpw', payload);
    return true;
  });
