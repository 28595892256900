import React from "react";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import { HelpLink, Logo } from 'components/atoms/atoms.styled';
import { BodyText } from 'components/atoms/typography/typography';
import { NewVerticalList } from "../../../templates";
import { SubmitButton } from "../../../atoms/buttons";
import FormLabel from "@material-ui/core/FormLabel";
import Card from "@material-ui/core/Card";
import { atHomeTheme } from "global-style";
import { TextField } from "../../../atoms/fields/index";
import { forgotPassword } from 'redux/actions/auth_actions';
import { enqueueSnack } from 'redux/modules/ui';
import { CenteredCircularLoader } from "components/atoms/loaders";

function LoginForm(props) {
  const { control, errors, handleSubmit } = useForm();
  const history = useHistory()
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async data => {
    const payload = {
      ...data,
      valid: true
    };

    setLoading(true);
    const success = await dispatch(forgotPassword(payload));
    setLoading(false);

    if (success) {
      dispatch(
        enqueueSnack({
          message: 'Check your email for a link to reset your password',
          variant: 'success',
        })
      );
    }
  }

  const sendToLogin = () => {
    history.push('/login')
  }

  return (
    <Card>
      <NewVerticalList center className={props.className}>
        <Logo />

        <form onSubmit={handleSubmit(onSubmit)} className={"login-form"}>
          <NewVerticalList>
            <BodyText style={{ padding: '1rem', fontWeight: 600 }}>
              Enter the email address associated with your account. If it exists,
              you'll get an email to reset your password.
            </BodyText>
            <Controller
              as={TextField}
              control={control}
              name="username"
              label="Email"
              fullWidth
              type="email"
              variant="outlined"
              error={errors.username ? true : false}
              helperText={errors.username ? errors.username.message : ''}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'A valid email is required',
                }
              }}
            />
            {loading ? (
              <CenteredCircularLoader />
            ) : (
              <SubmitButton fullWidth>Submit</SubmitButton>
            )}
          </NewVerticalList>
        </form>
        <BodyText>
          <HelpLink onClick={sendToLogin}>Login instead</HelpLink>
        </BodyText>
      </NewVerticalList>
    </Card>
  );
}

export default styled(LoginForm)`
  width: 400px;
    padding: 20px;
    text-align: center;
  .login-form {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: ${atHomeTheme.spacing(2)}px;
  }
  .forgot-form {
    display: flex;
    flex-flow: column nowrap;
  }
`;
