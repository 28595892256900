// @flow
import { createReducer } from 'redux/reducer-utils';
import { withErrorHandling } from 'redux/action-utils';
import { guidFactory } from 'components/utils';
import { endOfToday, subDays } from 'date-fns';

const today = endOfToday();
const snackGuid = guidFactory();
const getSnackGuid = () => snackGuid.next().value;

export const CLOSE_GLOBAL_MODAL = 'CLOSE_GLOBAL_MODAL';
export const DEQUEUE_SNACK = 'DEQUEUE_SNACK';
export const ENQUEUE_SNACK = 'ENQUEUE_SNACK';
export const UPDATE_GLOBAL_MODAL = 'UPDATE_GLOBAL_MODAL';
export const UPDATE_UI = 'UPDATE_UI';

const defaultState = {
  snackbar: [],
  modal: { isOpen: false, content: null, modalProps: {} },
  expandedLogs: [],
  logDateRange: { begin: subDays(today, 14), end: today },
};

export default createReducer((defaultState: Array), {
  [UPDATE_GLOBAL_MODAL]: (prevState, payload) => {
    const { content = null, modalProps = {} } = payload;
    return {
      ...prevState,
      modal: {
        isOpen: true,
        content: content,
        modalProps,
      },
    };
  },
  [CLOSE_GLOBAL_MODAL]: prevState => {
    return {
      ...prevState,
      modal: {
        isOpen: false,
        content: null,
        modalProps: {},
      },
    };
  },
  [ENQUEUE_SNACK]: (state, payload) => {
    const { snackbar, ...prevState } = state;
    const { snack } = payload;
    const keyedSnack = {
      ...snack,
      key: getSnackGuid(),
    };

    return {
      ...prevState,
      snackbar: [...snackbar, keyedSnack],
    };
  },
  [DEQUEUE_SNACK]: (state, payload) => {
    const { snackbar, ...prevState } = state;
    const { snackKey } = payload;
    return {
      ...prevState,
      snackbar: snackbar.filter(s => s.key !== snackKey),
    };
  },
  [UPDATE_UI]: (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  },
});

// asynchronous action creators
export const updateLogDateRange = (begin, end) => {
  return withErrorHandling(async dispatch => {
    dispatch({
      type: UPDATE_UI,
      logDateRange: {
        begin,
        end,
      },
    });
  });
};

export const enqueueSnack = snack => ({
  type: ENQUEUE_SNACK,
  snack,
});

export const openGlobalModal = (content, modalProps) => {
  return {
    type: UPDATE_GLOBAL_MODAL,
    content,
    modalProps,
  };
};

export const closeGlobalModal = () => ({
  type: CLOSE_GLOBAL_MODAL
});
