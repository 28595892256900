import React from 'react';
import { useDispatch } from 'react-redux';
import Table from 'components/molecules/cruddy-table/cruddy-table';
import { TableBodyText } from "../../atoms/typography/typography";
import { StyledAvatar } from 'components/atoms/atoms.styled';
import config from 'config';
import { Edit, Delete, LockOpen } from '@material-ui/icons';
import { deleteAdmin, activateAdmin } from 'redux/actions/admin_actions';
import { enqueueSnack } from 'redux/modules/ui';
import _ from 'lodash';

export default ({ users, editAction, ...props }) => {
  const dispatch = useDispatch();
  const columns = [
    {
      title: '',
      field: 'photo',
      render: (user) =>
        <StyledAvatar src={`${config.apiUrl}/image/${user.photo}`} alt={user.fullName} />,
      width: 100,
      filtering: false,
      export: false,
    },
    {
      title: 'Name',
      field: 'fullName',
      render: (user) =>
        <TableBodyText>{user.fullName}</TableBodyText>
    },
    {
      title: 'Email',
      field: 'username',
      render: (user) =>
        <TableBodyText>{user.username}</TableBodyText>
    },
    {
      title: 'Phone',
      field: 'phone',
      render: (user) =>
        <TableBodyText>{user.phone}</TableBodyText>
    },
    {
      title: 'Status',
      field: 'status',
      render: (user) =>
        <TableBodyText>
          {_.startCase(_.toLower(user.status.replace('_', ' ')))}
        </TableBodyText>,
      lookup: {
        ACTIVE: 'Active',
        AWAITING_ACTIVATION: 'Awaiting Activation',
        DELETED: 'Deleted',
      }
    },
  ];

  const disableUser = async (user) => {
    await dispatch(deleteAdmin(user.id));
    dispatch(
      enqueueSnack({
        message: 'User disabled',
        variant: 'success',
        timeout: 2500,
      })
    );
  }

  const enableUser = async (user) => {
    await dispatch(activateAdmin(user.id));
    dispatch(
      enqueueSnack({
        message: 'User activated',
        variant: 'success',
        timeout: 2500,
      })
    );
  }

  return (
    <>
      <Table
        style={{ boxShadow: 'none' }}
        data={users}
        cols={columns}
        actions={[
          {
            icon: Edit,
            tooltip: 'Edit User',
            onClick: (event, rowData) => editAction(rowData),
          },
          rowData => ({
            icon: rowData.status === 'ACTIVE' ? Delete : LockOpen,
            tooltip: rowData.status === 'ACTIVE' ? 'Disable User' : 'Enable User',
            onClick: rowData.status === 'ACTIVE' ? () => disableUser(rowData) : () => enableUser(rowData)
          })
        ]}
        opts={{
          toolbar: true,
          showTitle: false,
          paging: true,
          search: true,
          paginationType: 'stepped',
          pageSizeOptions: [10,20,50,100,200],
          pageSize: 20,
          filtering: true,
          exportButton: true,
          exportFileName: 'User Export',
        }}
        localization={{
          body: { emptyDataSourceMessage: 'No users found' }
        }}
      />
    </>
  );
}