import Card from '@material-ui/core/Card';
import React from 'react';

import { Logo } from '../../atoms/atoms.styled';
import { BodyText } from '../../atoms/typography/typography';
import { NewVerticalList } from '../../templates';

const AppReturn = () => {
  return (
    <Card>
      <NewVerticalList
        center
        style={{ padding: '20px', textAlign: 'center', width: '300px' }}>
        <Logo />
        <BodyText>Account Activated!</BodyText>
        <BodyText>Please return to the app to login</BodyText>
      </NewVerticalList>
    </Card>
  );
};

export default AppReturn;
