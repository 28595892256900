import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './modules/index';

const middlewares = [thunk];
const enhancers = [applyMiddleware(...middlewares)];
const composeEnhancers = composeWithDevTools({ trace: true });
const composedEnhancers = composeEnhancers(...enhancers);

export default createStore(rootReducer, composedEnhancers);

