import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Table from '../../molecules/cruddy-table/cruddy-table';
import { TableBodyText, HeaderText } from '../../atoms/typography/typography';
import { fetchPatientReportingRoutines } from '../../../redux/actions/report_actions';
import { format, fromUnixTime } from 'date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import DateRangePicker from 'components/molecules/reports/date-range-picker';

const ReportTable = ({ ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [routines, setRoutines] = useState([]);
  const [dates, setDates] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const startDate = useSelector((state) => state.report.startDate);
  const endDate = useSelector((state) => state.report.endDate);
  const patient = useSelector((state) => state.patient.patient);

  const columns = [
    {
      title: 'Name',
      field: 'name',
      render: (routine) => <TableBodyText>{routine.name}</TableBodyText>,
    },
    {
      title: 'Period',
      field: 'period',
      render: (routine) => <TableBodyText>{routine.period}</TableBodyText>,
    },
    {
      title: 'Date',
      field: 'scheduledDate',
      render: (routine) => (
        <TableBodyText>
          {format(fromUnixTime(routine.scheduledDate / 1000), 'MM/dd/yyyy')}
        </TableBodyText>
      ),
    },
    {
      title: 'Status',
      field: 'completed',
      render: (routine) => (
        <TableBodyText>
          {routine.completed ? 'Completed' : 'Incomplete'}
        </TableBodyText>
      ),
    },
    {
      title: 'Tasks',
      field: 'tasks',
      render: (routine) => (
        <TableBodyText>{routine.tasks.length}</TableBodyText>
      ),
    },
    {
      title: 'Procedures',
      field: 'careRoutines',
      numeric: true,
      render: (routine) => (
        <TableBodyText>{routine.careRoutines.length}</TableBodyText>
      ),
    },
  ];

  const getRoutines = async () => {
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();
    setLoading(true);
    const routines = await dispatch(
      fetchPatientReportingRoutines(patient.id, startTime, endTime)
    );
    setLoading(false);
    setRoutines(routines);
  };

  const handleRowClick = (event, rowData) => {
    const { id, scheduledDate } = rowData;
    history.push(`/dashboard/report/routines/${id}/${scheduledDate}`);
  };

  useEffect(() => {
    getRoutines();
  }, [startDate, endDate, patient]);

  return (
    <div className={props.className}>
      <TableWrapper>
        <Table
          opts={{
            search: true,
            toolbar: true,
            showTitle: true,
            exportButton: true,
            paginationType: 'stepped',
            pageSizeOptions: [10, 20, 50, 100, 200],
            pageSize: 20,
          }}
          localization={{
            body: { emptyDataSourceMessage: 'No routines for this period' },
          }}
          components={{
            Toolbar: (props) => (
              <Grid
                container
                justify="space-between"
                alignItems="center"
                md={12}>
                <DateFieldWrapper>
                  <HeaderText>Routine Reporting</HeaderText>
                </DateFieldWrapper>
                <Grid container justify="flex-end" alignItems="center" md={8}>
                  <DateRangePicker />
                </Grid>
              </Grid>
            ),
          }}
          cols={columns}
          data={routines}
          onRowClick={handleRowClick}
          isLoading={loading}
        />
      </TableWrapper>
    </div>
  );
};

export default ReportTable;

const TableWrapper = styled('div')`
  padding: 1rem;
`;

const DateFieldWrapper = styled('div')`
  padding: 1rem;
`;
