import { api } from '../../api';
import { withErrorHandling } from '../action-utils';
import {
  setRoutines,
  setCareRoutes,
  setMeasurementUnits,
} from '../modules/patient';

export const getPatientRoutines = (patientId) =>
  withErrorHandling(async (dispatch) => {
    const { data: routines } = await api.get(`/routines/patient/${patientId}`);
    dispatch(setRoutines(routines));
    return routines;
  });

export const getRoutine = (routineId) =>
  withErrorHandling(async (dispatch) => {
    const { data: routine } = await api.get(`/routines/${routineId}`);
    return routine;
  });

export const getCareRoutes = () =>
  withErrorHandling(async (dispatch) => {
    const { data: careRoutes } = await api.get('/care-routes');
    dispatch(setCareRoutes(careRoutes));
    return careRoutes;
  });

export const getMeasurementUnits = () =>
  withErrorHandling(async (dispatch) => {
    const { data: measurementUnits } = await api.get('/measurement-units');
    dispatch(setMeasurementUnits(measurementUnits));
    return measurementUnits;
  });
