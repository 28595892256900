import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Delete } from '@material-ui/icons';
import { SpaceBetweenWrapper } from '../../templates';
import moment from 'moment';
import { Divider, Grid, Chip } from '@material-ui/core';
import { getPatientRoutines } from '../../../redux/actions/routine_actions';
import {
  getPatientCareRoutines,
  getPatientInventory,
  getPatientTasks,
} from 'redux/actions/patient_actions';
import { HeaderText, BodyText } from '../../atoms/typography/typography';
import { openGlobalModal } from 'redux/modules/ui';
import NoRecords from 'components/molecules/no-records';
import { CenteredCircularLoader } from 'components/atoms/loaders';

const labelForRoutineScheduleType = (type) => {
  switch (type) {
    case 'WEEKLY':
      return 'Weekly';
    case 'MONTHLY':
      return 'Monthly';
    case 'DAILY':
      return 'Daily';
    default:
      return '';
  }
};

const labelForCareType = (type) => {
  switch (type) {
    case 'PROCEDURE':
      return 'Supply';
    case 'MEDICATION':
      return 'Medication';
    case 'NUTRITION':
      return 'Nutrition';
    default:
      return '';
  }
};

const RoutineCare = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const patientId = useSelector((state) => state.patient.patient.id);
  const routines = useSelector((state) => state.patient.routines);

  const viewMoreDetail = (info, type) => {
    const modalProps = { title: info.name, fullWidth: true };
    dispatch(
      openGlobalModal(
        <div className={props.className}>
          <article className="modal-details">
            {type ? null : (
              <div className="grid-3">
                <div>
                  <label htmlFor="" className="capitalize">
                    {labelForCareType(info.careRoutineType)}
                  </label>
                  <span>
                    {
                      info[labelForCareType(info.careRoutineType).toLowerCase()]
                        ?.name
                    }
                  </span>
                </div>
                <div>
                  <label htmlFor="" className="capitalize">
                    Route
                  </label>
                  <span>{info.route.name}</span>
                </div>
                <div>
                  <label htmlFor="" className="capitalize">
                    Amount
                  </label>
                  <span>
                    {info.expectedAmount} {info?.unit?.name ?? info.supply.name}
                  </span>
                </div>
              </div>
            )}
            <label htmlFor="">Instructions</label>
            <p>{info.instructions}</p>
            {info.additionalNotes && (
              <>
                <br />
                <label htmlFor="">Additional Notes</label>
                <p>{info.additionalNotes}</p>
              </>
            )}
          </article>
        </div>,
        modalProps
      )
    );
  };

  const initData = async () => {
    setLoading(true);
    await dispatch(getPatientRoutines(patientId));
    await dispatch(getPatientCareRoutines(patientId));
    await dispatch(getPatientInventory(patientId));
    await dispatch(getPatientTasks(patientId));
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [patientId]);

  return (
    <div className={props.className} style={{ padding: '1rem 3rem' }}>
      <Grid container justify="space-between" style={{ marginBottom: '2rem' }}>
        <Grid item>
          <HeaderText>Routines</HeaderText>
        </Grid>
      </Grid>
      {routines.length > 0 ? (
        <Grid container spacing={5}>
          {routines.map((rout, idx) => (
            <Grid item md={6} key={idx}>
              <div className="routine-card" key={rout.id}>
                <Grid container spacing={4}>
                  <Grid item md={12}>
                    <SpaceBetweenWrapper>
                      <h2>{rout.name}</h2>
                      {rout.recordStatuses === 'DELETED' && (
                        <Chip
                          color="primary"
                          label="Archived"
                          icon={<Delete />}
                        />
                      )}
                    </SpaceBetweenWrapper>
                    <Divider />
                  </Grid>
                  <Grid item md={12}>
                    <Chip
                      variant="outlined"
                      color="primary"
                      label={labelForRoutineScheduleType(rout.period)}
                    />{' '}
                    {moment(rout.startingAt).format('LT')}
                  </Grid>
                  <Grid item md={12}>
                    <BodyText>{rout.info}</BodyText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <h4 className="bold">Routine Care</h4>
                    <ul>
                      {rout.routineCareProcedures.map((proc, idx) => (
                        <li
                          key={idx}
                          onClick={() => viewMoreDetail(proc, null)}>
                          <article>
                            <BodyText>{proc.name}</BodyText>
                          </article>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <h4 className="bold">Tasks</h4>
                    <ul>
                      {rout.tasks.map((task, idx) => (
                        <li key={idx}>
                          <BodyText
                            onClick={() => viewMoreDetail(task, 'task')}>
                            {' '}
                            {task.name}
                          </BodyText>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          {loading ? (
            <CenteredCircularLoader />
          ) : (
            <NoRecords
              headerText="No Routines Found"
              bodyText="This patient has had any routines entered for them"
            />
          )}
        </>
      )}
    </div>
  );
};

export default styled(RoutineCare)`
  .grid-head {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    margin: 0.5rem;
    button {
      align-self: center;
      justify-self: end;
      background-color: transparent;
      color: #d33939 !important;
      border: none;
      font-size: 14px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .pop {
    padding: 1rem;
  }
  .grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
    padding: 8px;
    margin-bottom: 2rem;
    text-align: center;
  }

  .routine-card {
    padding: 1rem;
    border-radius: 0.25rem;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);

    .header-line {
      margin-bottom: 2rem;
    }

    ul {
      ${'' /* margin: 1rem; */}
      li {
        margin-bottom: 12px;
        &:hover {
          color: #d33939 !important;
          cursor: pointer;
        }
      }
    }
    &__head {
      padding: 1rem;
      border-bottom: solid 1px hsl(210, 3%, 93.5%);
      ${'' /* display: grid; */}
      ${'' /* grid-template-columns: repeat(2, 1fr); */}
       button {
        align-self: center;
        justify-self: end;
        background-color: transparent;
        color: #d33939 !important;
        border: none;
        font-size: 14px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    &__info {
      padding: 1rem;
      margin-left: 16px;
      display: flex;
      ${'' /* justify-content: space-evenly; */}
    }
  }
  h4 {
    font-size: 14px;
    color: hsl(210, 3%, 42.7%);
  }
  .bold {
    font-weight: 600;
    margin-bottom: 12px;
  }
  .modal-details {
    padding: 1rem;
    h4 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 12px;
    }
    label {
      font-size: 14px;
      font-weight: 600;
    }
    p {
      line-height: 1.4;
      font-size: 14px;
      margin-top: 12px;
    }
    span {
      display: block;
      margin-top: 8px;
    }
  }
  .capitalize {
    text-transform: capitalize;
  }
`;
