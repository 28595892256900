import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { Input, Logo, PrimaryHelpLink } from '../../../atoms/atoms.styled';
import { NewVerticalList, VerticalList } from '../../../templates';
import { SubmitButton } from '../../../atoms/buttons';
import FormLabel from '@material-ui/core/FormLabel';
import Card from '@material-ui/core/Card';
import { atHomeTheme } from 'global-style';
import { StyledTextField } from '../../../atoms/fields/index';
import { CenteredCircularLoader } from 'components/atoms/loaders';
import { resetPassword } from 'redux/actions/auth_actions';
import { enqueueSnack } from 'redux/modules/ui';
import { BodyText } from 'components/atoms/typography/typography';

function LoginForm(props) {
  const { errors, handleSubmit, control, getValues } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const { passwordRequestToken } = useParams();

  const fieldProps = {
    as: StyledTextField,
    control,
    defaultValue: '',
    variant: 'outlined',
  };

  const validatePasswordsMatch = (value) => {
    return value === getValues().newPassword ? true : 'Passwords must match';
  };

  const handlePasswordReset = async (values) => {
    const payload = {
      ...values,
      valid: true,
      passwordRequestToken,
    };

    setLoading(true);
    const response = await dispatch(resetPassword(payload));
    setLoading(false);

    if (response) {
      dispatch(
        enqueueSnack({
          message:
            'Your password has been reset. Please return and login using your new credentials',
          variant: 'success',
          timeout: 5000,
        })
      );
      history.push('/login/reset-password-success');
    }
  };

  const sendToLogin = () => {
    history.push('/login');
  };

  return (
    <Card>
      <NewVerticalList center className={props.className}>
        <Logo />

        <form
          onSubmit={handleSubmit(handlePasswordReset)}
          className={'login-form'}>
          <FormLabel>
            Please enter your new password below. After resetting your password
            you'll be able to login with your new credentials.
          </FormLabel>
          <Controller
            {...fieldProps}
            name="newPassword"
            label="Password"
            type="password"
            pattern="/[A-Za-z]{3}/"
            rules={{
              required: '*Password is required',
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                message: `Passwords must be 8 characters long, contain 1 upper case letter,
                          1 lower case lettter, 1 number and 1 special character.`,
              },
            }}
            error={errors.newPassword ? true : false}
            helperText={errors.newPassword ? errors.newPassword.message : ''}
          />
          <Controller
            {...fieldProps}
            name="newPasswordConfirmation"
            label="Re-Type Password"
            type="password"
            rules={{
              required: '*Password is required',
              validate: validatePasswordsMatch,
            }}
            error={errors.newPasswordConfirmation ? true : false}
            helperText={
              errors.newPasswordConfirmation
                ? errors.newPasswordConfirmation.message
                : ''
            }
          />
          {loading ? (
            <CenteredCircularLoader />
          ) : (
            <SubmitButton fullWidth>Submit</SubmitButton>
          )}
          <BodyText>
            <PrimaryHelpLink onClick={sendToLogin} color="primary">
              Login Instead
            </PrimaryHelpLink>
          </BodyText>
        </form>
      </NewVerticalList>
    </Card>
  );
}

export default styled(LoginForm)`
  width: 300px;
  padding: 20px;
  text-align: center;
  .login-form {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: ${atHomeTheme.spacing(2)}px;
  }
  .forgot-form {
    display: flex;
    flex-flow: column nowrap;
  }
`;
