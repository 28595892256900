import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Table from '../../molecules/cruddy-table/cruddy-table';
import { TableBodyText, HeaderText } from '../../atoms/typography/typography';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { getPatientTasks } from 'redux/actions/patient_actions';

const TaskTable = ({ ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const patient = useSelector((state) => state.patient.patient);

  const columns = [
    {
      title: 'Name',
      field: 'name',
      render: (task) => <TableBodyText>{task.name}</TableBodyText>,
    },
    {
      title: 'Type',
      field: 'taskType',
      render: (task) => (
        <TableBodyText>
          {_.startCase(_.toLower(task.taskType.replace('_', '')))}
        </TableBodyText>
      ),
    },
    {
      title: 'Input Type',
      field: 'inputLabel',
      render: (task) => (
        <TableBodyText>
          {task.taskType === 'INPUT' ? task.inputLabel : '-'}
        </TableBodyText>
      ),
    },
  ];

  const getTasks = async () => {
    setLoading(true);
    const tasks = await dispatch(getPatientTasks(patient.id));
    setLoading(false);
    setTasks(tasks);
  };

  const handleRowClick = (event, rowData) => {
    const { id } = rowData;
    history.push(`/dashboard/report/task/${id}`);
  };

  useEffect(() => {
    getTasks();
  }, [patient.id]);

  return (
    <div className={props.className}>
      <TableWrapper>
        <Table
          opts={{
            toolbar: true,
            exportButton: true,
            paginationType: 'stepped',
            pageSizeOptions: [10, 20, 50, 100, 200],
            pageSize: 20,
          }}
          localization={{
            body: { emptyDataSourceMessage: 'No tasks for this period' },
          }}
          components={{
            Toolbar: (props) => (
              <Grid
                container
                justify="space-between"
                alignItems="center"
                md={12}>
                <DateFieldWrapper>
                  <HeaderText>Task Reporting</HeaderText>
                </DateFieldWrapper>
              </Grid>
            ),
          }}
          cols={columns}
          data={tasks}
          isLoading={loading}
          onRowClick={handleRowClick}
        />
      </TableWrapper>
    </div>
  );
};

export default TaskTable;

const TableWrapper = styled('div')`
  padding: 1rem;
`;

const DateFieldWrapper = styled('div')`
  padding: 1rem;
`;
