import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PatientTable from 'components/organisms/patient-table';
import { getAdminPatients } from 'redux/actions/admin_actions';
import { TableSkeleton } from 'components/atoms/loaders';
import { HeaderText } from 'components/atoms/typography/typography';
import { AdminHeader } from 'components/atoms/atoms.styled';

export default ({ ...props }) => {
  const dispatch = useDispatch();
  const patients = useSelector(state => state.patient.patients);
  const [loading, setLoading] = React.useState(false);

  const fetchPatients = async () => {
    setLoading(true);
    await dispatch(getAdminPatients());
    setLoading(false);
  }

  React.useEffect(() => {
    fetchPatients();
  }, []);

  return (
    <>
      <AdminHeader>
        <HeaderText>Patients</HeaderText>
      </AdminHeader>
      {loading ? (
        <TableSkeleton />
      ) : (
        <>
          <PatientTable patients={patients} />
        </>
      )}
    </>
  );
}