import React from 'react';
import styled from 'styled-components';
import { Switch, useLocation } from 'react-router-dom';
import PatientList from 'components/organisms/admin-lists/patient-list';
import UserList from 'components/organisms/admin-lists/user-list';
import CaregiverList from 'components/organisms/admin-lists/caregiver-list';
import ProtectedRoute from 'components/protected-route';
import DashboardView from 'routes/dashboard/dashboard-view';
import ReportView from 'routes/reports/report-view';

const adminRoutes = {
  PATIENTS: '/dashboard/admin/patients',
  CAREGIVERS: '/dashboard/admin/caregivers',
  USERS: '/dashboard/admin/users',
  PATIENT: '/dashboard/admin/patient/:patientId',
  REPORT: '/dashboard/admin/patient/:patientId/report',
};

function Activate(props) {
  const location = useLocation();
  return (
    <div className={props.className}>
      <Switch location={location}>
        <ProtectedRoute
          exact
          path={adminRoutes.PATIENTS}
          component={PatientList}
        />
        <ProtectedRoute
          exact
          path={adminRoutes.CAREGIVERS}
          component={CaregiverList}
        />
        <ProtectedRoute exact path={adminRoutes.USERS} component={UserList} />
        <ProtectedRoute
          exact
          path={adminRoutes.PATIENT}
          component={DashboardView}
        />
        <ProtectedRoute
          exact
          path={adminRoutes.REPORT}
          component={ReportView}
        />
      </Switch>
    </div>
  );
}

export default styled(Activate)`
  min-height: 100%;
  ${'' /* padding: 2rem; */}
`;
