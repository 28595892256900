// @flow
import { createReducer } from 'redux/reducer-utils';
import type { PatientResponse } from 'data-flows';

export const SET_PATIENTS = 'SET_PATIENTS';

const defaultState = [];

export default createReducer((defaultState: Array), {
  [SET_PATIENTS]: (state, { patients = [] }) => {
    return patients;
  },
});

// synchronous action creators
export const setPatients = (patients: Array<PatientResponse>) => {
  return {
    type: SET_PATIENTS,
    patients,
  };
};
