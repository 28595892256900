import { withErrorHandling } from '../action-utils';
import { api } from '../../api';

export const fetchPatientReportingRoutines = (patientId, startTimestamp, endTimestamp) =>
  withErrorHandling(async dispatch => {
    const { data: routines } = await api.get(`/routines/report/patient/${patientId}/start/${startTimestamp}/end/${endTimestamp}`);
    return routines;
  });

export const fetchRoutineDayReport = (routineId, timestamp) =>
  withErrorHandling(async dispatch => {
    const { data: routine} = await api.get(`/routines/${routineId}/day/${timestamp}`);
    return routine;
  });

export const fetchCareRoutineReport = (careRoutineId, startTimestamp, endTimestamp) =>
  withErrorHandling(async dispatch => {
    const { data: report } = await api.get(`/care-routines/${careRoutineId}/report?startDate=${startTimestamp}&endDate=${endTimestamp}`);
    return report;
  });

export const fetchTaskReport = (taskId, startTimestamp, endTimestamp) =>
  withErrorHandling(async dispatch => {
    const { data: report } = await api.get(`/tasks/${taskId}/report?startDate=${startTimestamp}&endDate=${endTimestamp}`);
    return report;
  });

export const fetchTask = (taskId) =>
  withErrorHandling(async dispatch => {
    const { data: task } = await api.get(`/tasks/${taskId}`);
    return task;
  });

export const fetchCare = (careId) =>
  withErrorHandling(async dispatch => {
    const { data: care } = await api.get(`/care-routines/${careId}`);
    return care;
  });