import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoginRouter from './routes/login';
import DashboardRouter from './routes/dashboard';
import ActivationRouter from './routes/activation'
import SignupRouter from './routes/signup';
import PasswordRouter from './routes/password';

const appRoutes = {
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  ACTIVATE: '/activate',
  SIGNUP: '/signup',
  PASSWORD: '/pwreset'
};

export default function AppRouter() {
  return (
    <Switch>
      <Route path={appRoutes.LOGIN} component={LoginRouter} />
      <Route path={appRoutes.DASHBOARD} component={DashboardRouter} />
      <Route path={appRoutes.ACTIVATE} component={ActivationRouter} />
      <Route path={appRoutes.SIGNUP} component={SignupRouter} />
      <Route path={appRoutes.PASSWORD} component={PasswordRouter} />
      <Redirect to={appRoutes.LOGIN} />
    </Switch>
  );
}
