import { api } from '../../api';
import { withErrorHandling } from '../action-utils';
import {
  patientUpdate,
  setNutrition,
  setMedication,
  setSupplies,
  setCareRoutines,
  setTasks,
  setPatient,
} from '../modules/patient';
import { getCareRoutes, getMeasurementUnits } from './routine_actions';

export const getPatientById = (patientId) =>
  withErrorHandling(async (dispatch) => {
    const { data: patient } = await api.get(`/patients/${patientId}`);
    dispatch(setPatient(patient));
    return patient;
  });

export const getPatientCareRoutines = (patientId) =>
  withErrorHandling(async (dispatch) => {
    const { data: careRoutines } = await api.get(
      `/care-routines/patient/${patientId}`
    );
    dispatch(setCareRoutines(careRoutines));
    return careRoutines;
  });

export const getPatientTasks = (patientId) =>
  withErrorHandling(async (dispatch) => {
    const { data: tasks } = await api.get(`/tasks/patient/${patientId}`);
    dispatch(setTasks(tasks));
    return tasks;
  });

export const getPatientInventory = (patientId) => (dispatch) => {
  dispatch(getPatientNutritionItems(patientId));
  dispatch(getPatientMedication(patientId));
  dispatch(getPatientSupplies(patientId));
  dispatch(getCareRoutes());
  dispatch(getMeasurementUnits());
};

export const getPatientNutritionItems = (patientId) =>
  withErrorHandling(async (dispatch) => {
    const { data: nutrition } = await api.get(
      `/nutrition/patient/${patientId}`
    );
    dispatch(setNutrition(nutrition));
    return nutrition;
  });

export const getPatientMedication = (patientId) =>
  withErrorHandling(async (dispatch) => {
    const { data: medication } = await api.get(
      `/medications/patient/${patientId}`
    );
    dispatch(setMedication(medication));
    return medication;
  });

export const getPatientSupplies = (patientId) =>
  withErrorHandling(async (dispatch) => {
    const { data: supplies } = await api.get(
      `/procedure-supplies/patient/${patientId}`
    );
    dispatch(setSupplies(supplies));
    return supplies;
  });

export const deletePatient = (patientId) =>
  withErrorHandling(async (dispatch) => {
    const { data: patient } = await api.delete(`/patients/${patientId}`);
    dispatch(patientUpdate(patient));
    return patient;
  });

export const activatePatient = (patientId) =>
  withErrorHandling(async (dispatch) => {
    const { data: patient } = await api.put(`/patients/${patientId}/activate`);
    dispatch(patientUpdate(patient));
    return patient;
  });
