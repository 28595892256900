import React from 'react';
import { BodyText } from 'components/atoms/typography/typography';
import { useLocation } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import { Group, Face, VerifiedUser } from '@material-ui/icons';

export default ({ navigateTo, dashboardRoutes, ...props }) => {
  const location = useLocation();

  return (
    <>
      <ListItem
        button
        divider
        selected={location.pathname === dashboardRoutes?.ADMIN_PATIENTS}
        onClick={() => navigateTo(dashboardRoutes?.ADMIN_PATIENTS)}
        className="nav-list-item">
        <Face className="styled-icon" />
        <BodyText>Patients</BodyText>
      </ListItem>
      <ListItem
        button
        divider
        selected={location.pathname === dashboardRoutes?.ADMIN_CAREGIVERS}
        onClick={() => navigateTo(dashboardRoutes?.ADMIN_CAREGIVERS)}
        className="nav-list-item">
        <Group className="styled-icon" />
        <BodyText>Caregivers</BodyText>
      </ListItem>
      <ListItem
        button
        divider
        selected={location.pathname === dashboardRoutes?.ADMIN_USERS}
        onClick={() => navigateTo(dashboardRoutes?.ADMIN_USERS)}
        className="nav-list-item">
        <VerifiedUser className="styled-icon" />
        <BodyText>Administrators</BodyText>
      </ListItem>
    </>
  )
}