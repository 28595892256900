import styled from "styled-components/macro";
import { Avatar, Box } from "@material-ui/core";
import { atHomeTheme } from "../global-style";

export const SpaceBetweenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: ${props => (props.alignment ? props.alignment : 'center')};
  height: auto;
  flex-flow: ${props => (props.vertical ? "column nowrap" : "row nowrap")};
`;

export const ProfilePicWrapper = styled(Avatar)`
  img {
    object-fit: cover;
  }
`;

export const VerticalList = styled.section`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  width: 100%;
  justify-items: ${props => (props.center ? "center" : "stretch")};
`;
export const NewVerticalList = styled.section`
  display: grid;
  grid-auto-flow: row;
  grid-gap: .5rem;
  width: 100%;
  justify-items: ${props => (props.center ? "center" : "stretch")};
`;

export const HorizontalList = styled(Box)`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: ${atHomeTheme.spacing(1)}rem;
  align-items: center;
`;

export const StyledChipList = styled.section`
  display: flex;
  flex-flow: row nowrap;
  > *:not(:last-child) {
    margin-right: 5px;
  }
`;

export const ExpandLogList = styled.section`
  display: flex;
  flex-wrap: nowrap !important;
  justify-content: space-around;
  overflow: hidden;

  > *:not(:last-child) {
    border-right: 1px solid rgba(224, 224, 224, 1);
  }
  .cellExpand {
    padding: 20px;
    max-width: 200px;
  }
  .eDescription {
    max-width: 600px;
  }
  .imgList {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .imgList img {
    flex-shrink: 2;
    flex-wrap: nowrap;
  }
`;
