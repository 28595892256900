import React from 'react';
import styled from 'styled-components';
import { BodyText, HeaderText } from 'components/atoms/typography/typography';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { atHomeTheme } from 'global-style';
import ListItem from '@material-ui/core/ListItem';
import { ExitToApp } from '@material-ui/icons';
import { ProfilePicWrapper } from '../../templates';
import List from '@material-ui/core/List';
import { logOut } from '../../../redux/actions/auth_actions';
import AdminNavItems from './admin-nav-items';
import config from 'config';

function SideBarNav(props) {
  const history = useHistory();
  const identity = useSelector((state) => state.self);
  const navigateTo = (pathname) => history.push(pathname);
  const dispatch = useDispatch();

  const logoutReq = () => {
    dispatch(logOut());
    sessionStorage.removeItem('token');
    history.push('/login');
  };
  const { className, dashboardRoutes } = props;

  return (
    <aside className={className}>
      <section className="sidebar-row name-row">
        <div className="sidebar-row__details">
          <BodyText className="username uppercase">
            {identity?.fullName ?? ''}
          </BodyText>
          <ProfilePicWrapper
            src={`${config.apiUrl}/image/${identity?.photo}`}
            height={atHomeTheme.spacing(8)}
            style={{ justifySelf: 'end' }}
          />
        </div>
      </section>
      <List component="nav" color="secondary">
        <AdminNavItems
          navigateTo={navigateTo}
          dashboardRoutes={dashboardRoutes}
        />
        <ListItem button divider onClick={logoutReq} className="nav-list-item">
          <ExitToApp className="styled-icon" />
          <BodyText>Log Out</BodyText>
        </ListItem>
      </List>
    </aside>
  );
}

export default styled(SideBarNav)`
  background: ${atHomeTheme.palette.white.main};
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  //width: inherit;
  transition: width 1s ease-in;
  > * {
    margin-bottom: ${atHomeTheme.spacing(3)}px;
  }
  .styled-icon {
    height: ${atHomeTheme.typography.body1.fontSize};
    color: ${atHomeTheme.palette.primary.main};
    margin-right: ${atHomeTheme.spacing(2)}px;
  }

  ${HeaderText}, & .MuiFormLabel-root {
    color: ${atHomeTheme.palette.secondary.contrastText};
  }

  .nav-list-item {
    ${atHomeTheme.typography.h5};
    color: ${atHomeTheme.palette.offBlack.main};
    height: 50px;
  }

  .sidebar-row {
    justify-content: space-between;
    padding: ${atHomeTheme.spacing(4)}px;
    width: 100%;
    &__details {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      
      .username {
        color: #FFFFFF;
      }
    }
  }
  .name-row {
    background-color: ${atHomeTheme.palette.primary.main} !important;
  }

  .side-bar-nav-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    padding: ${atHomeTheme.spacing(1)}px;
    max-height: 80px;
    align-items: center;
    box-sizing: content-box;
    width: 100%;

    .logo-text {
      display: flex;
      align-content: center;
      height: 100%;
      width: min-content;
      flex-flow: column nowrap;
      justify-content: center;
      justify-items: center;
      align-items: center;
      text-align: center;
      //margin-left: 12px;
      font-family: "Slabo 27px", cursive;
    }
  }
  .helpFab {
    bottom: ${atHomeTheme.spacing(3)}px;
    position: absolute;
    left: ${atHomeTheme.spacing(3)}px;
    background-color: ${atHomeTheme.palette.secondary} !important;
  }
  .envelope-icon {
    width: 20px;
    margin-right: ${atHomeTheme.spacing(2)}px;
  }
  // .toolbar-wedge {
  //   ${atHomeTheme.mixins.toolbar}
  // }
  .uppercase {
    text-transform: capitalize;
  }
  .selector {
    margin-top: 12px;
    
    width: 100%;
  }
  .MuiInputBase-root {
    color: #fff; 
  }
  .MuiInput-underline:before {
    border-bottom: solid 1px #fff;
  }
  .MuiSelect-icon {
    color: #fff;
  }
`;
