import Card from '@material-ui/core/Card';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Logo, PrimaryHelpLink } from '../../atoms/atoms.styled';
import { BodyText } from '../../atoms/typography/typography';
import { NewVerticalList } from '../../templates';

const ResetSuccessful = () => {
  const history = useHistory();

  const sendToLogin = () => {
    history.push('/login');
  }
  return (
    <Card>
      <NewVerticalList
        center
        style={{ padding: '20px', textAlign: 'center', width: '300px' }}>
        <Logo />
        <BodyText>Password Successfully Reset!</BodyText>
        <BodyText>Please return to the app to login</BodyText>
        <BodyText>or</BodyText>
        <BodyText>
            <PrimaryHelpLink onClick={sendToLogin} color="primary">Return to Login</PrimaryHelpLink>
          </BodyText>
      </NewVerticalList>
    </Card>
  );
};

export default ResetSuccessful;
