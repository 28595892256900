import axios from 'axios';
import config from 'config';

export const clearAuthHeader = () => {
  delete api.defaults.headers.common['Authorization'];
  sessionStorage.removeItem('token');
};

export const setAuthHeader = (token) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  sessionStorage.setItem('token', token);
};

export const api = axios.create({
  // withCredentials: true,
  baseURL: config.apiUrl,
  responseType: 'json',
  timeout: 7500,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

/************** AUTH API & CONFIG *************/
export const loginReq = (data) =>
  api.request({
    url: '/auth/signin/dashboard',
    method: 'POST',
    data: data,
  });
export const getEndUserData = () =>
  api.request({
    url: '/auth/me',
    method: 'GET',
  });
export const checkEmailExist = (email) =>
  api.request({
    url: `users/email/${email}?includeInactive=true`,
    method: 'GET',
  });
export const forgotPass = (data) =>
  api.request({
    url: `users/forgotpw`,
    method: 'POST',
    data: data,
  });
export const updatePassword = (data, id) =>
  api.request({
    url: `users/${id}/pw`,
    method: 'POST',
    data: data,
  });
/************** PATIENT API *************/
export const getPatients = () =>
  api.request({
    url: '/patients',
    method: 'GET',
  });
export const getPatientsForCaregiver = (id) =>
  api.request({
    url: `/caregivers/${id}/patients`,
    method: 'GET',
  });
export const deletePatient = (id) =>
  api.request({
    url: `/patients/${id}`,
    method: 'DELETE',
  });
/************** ROUTINE API *************/
export const getRoutineByPatID = (id) =>
  api.request({
    url: `/routines/patient/${id}`,
    method: 'GET',
  });

/************** CAREGIVER API *************/
export const getAllCaregivers = () =>
  api.request({
    url: `/caregivers`,
    method: 'GET',
  });

/************** ADMIN API *************/
export const updateAdmin = (data) =>
  api.request({
    url: '/admins',
    method: 'PUT',
    data: data,
  });

export default api;
