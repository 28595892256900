import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CaregiverTable from 'components/organisms/caregiver-table';
import { getCaregivers } from 'redux/actions/admin_actions';
import { TableSkeleton } from 'components/atoms/loaders';
import { HeaderText } from 'components/atoms/typography/typography';
import { AdminHeader } from 'components/atoms/atoms.styled';

export default ({ ...props }) => {
  const caregivers = useSelector((state) => state.admin.caregivers);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const fetchCaregivers = async () => {
    setLoading(true);
    await dispatch(getCaregivers());
    setLoading(false);
  };

  React.useEffect(() => {
    fetchCaregivers();
  }, []);

  return (
    <>
      <AdminHeader>
        <HeaderText>Caregivers</HeaderText>
      </AdminHeader>
      {loading ? <TableSkeleton /> : <CaregiverTable caregivers={caregivers} />}
    </>
  );
};
