import styled from 'styled-components';
import React from 'react';
import { atHomeTheme } from '../../../global-style';
import { PlusSquare } from 'styled-icons/fa-regular';
import { Button } from '@material-ui/core';
import { SpaceBetweenWrapper } from 'components/templates';
import { CenteredCircularLoader } from '../loaders';

export const DefaultButton = styled(Button).attrs(props => ({
  color: 'primary',
  ...props,
}))`
  background: ${atHomeTheme.palette.primary.light} !important;
  border-radius: 4px !important;
  color: ${atHomeTheme.palette.primary.contrastText} !important;
  padding: ${atHomeTheme.spacing(1)}px ${atHomeTheme.spacing(2)}px !important;
  ${atHomeTheme.typography.button};
  width: ${props => (props.fullWidth ? '100%' : 'max-content')};
`;

export const SecondaryButton = styled(Button).attrs(props => ({
  color: 'secondary',
  ...props,
}))`
  background: ${atHomeTheme.palette.secondary.main} !important;
  border-radius: 4px !important;
  color: ${atHomeTheme.palette.secondary.contrastText} !important;
  padding: ${atHomeTheme.spacing(1)}px ${atHomeTheme.spacing(2)}px !important;
  ${atHomeTheme.typography.button};
  width: ${props => (props.fullWidth ? '100%' : 'max-content')};
`;

export const CTAButton = styled(DefaultButton)`
  background: ${atHomeTheme.palette.secondary.main} !important;
`;

export const AddNewNoteButton = props => {
  return (
    <DefaultButton {...props}>
      <PlusSquare
        style={{
          height: atHomeTheme.typography.button.fontSize,
          marginRight: atHomeTheme.spacing(2),
        }}
      />
      Add a New Note
    </DefaultButton>
  );
};

export const SubmitButton = styled(DefaultButton).attrs(props => ({
  ...props,
  type: 'submit',
}))`
  background-color: #d33939 !important;
  font-size: 18px !important;
  margin: 10px 0 !important;
`;

export const FormSubmitButtons = ({
  onCancel,
  cancelTitle,
  saveTitle,
  onSave,
  loading,
  ...props
}) => (
  <StickyFooter>
    {loading ? (
      <CenteredCircularLoader />
    ) : (
      <SpaceBetweenWrapper>
        {onCancel &&
          <SecondaryButton onClick={onCancel}>
            {cancelTitle || 'Cancel'}
          </SecondaryButton>
        }
        <DefaultButton onClick={onSave} color="primary">
          {saveTitle || 'Save'}
        </DefaultButton>
      </SpaceBetweenWrapper>
    )}
  </StickyFooter>
)

const StickyFooter = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 1rem 0;
  background-color: ${atHomeTheme.palette.white.main};
  z-index: 999;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;
