import styled from "styled-components";
import { Typography, FormLabel } from "@material-ui/core";
import { atHomeTheme } from "../../../global-style";

export const BodyText = styled(Typography)`
    font-size: .75rem!important;
    font-weight: 700;
    line-height: 1.5;
`;

export const TableBodyText = styled(Typography)`
 font-size: 14px!important;
`;
export const HeaderText = styled(Typography).attrs(props => ({
  variant: "h6",
  color: "primary",
  ...props
}))`
  font-weight: 600!important;
`;

export const MessageText = styled(Typography).attrs(props => ({
  variant: 'body2',
  color: 'secondary'
}))``;


export const MessageSubject = styled(Typography).attrs(props => ({
  variant: "body2",
  color: "primary",
  ...props
}))`
  font-size: ${atHomeTheme.typography.h6.fontSize};
`;

export const TitleText = styled(Typography).attrs(props => ({
  ...props
}))``;

// export const CardHeader = styled(BodyText)`
//   font-size: 2rem;
//   font-weight: 500;
//   color: #577da1;
// `;

export const CardHeader = styled(Typography).attrs(props => ({
  variant: "h6",
  ...props
}))``;

export const StyledError = styled(FormLabel)`
  text-align: left;
  font-weight: 600!important;
  color: ${atHomeTheme.palette.primary.main}!important;
`;
