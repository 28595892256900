import React from 'react';
import { Grid } from '@material-ui/core';
import { CardHeader, BodyText } from 'components/atoms/typography/typography';
import StyleIcon from '@material-ui/icons/Style';
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  icon: {
    color: theme.palette.offBlack.main
  },
  center: {
    textAlign: 'center'
  },
  container: {
    height: '50vh'
  }
});

const NoRecords = ({ classes, headerText, bodyText, ...props }) => {
  return (
    <Grid
      className={classes.container}
      container
      justify="center"
      alignContent="center"
      alignItems="center"
      spacing={4}
    >
      <Grid item xs={12} sm={12} md={12} className={classes.center}>
        <StyleIcon className={classes.icon} fontSize="large" />
      </Grid>
      <Grid item xs={12} sm={12}  md={12} className={classes.center}>
        <CardHeader className={classes.icon}>{headerText || 'No records found'}</CardHeader>
      </Grid>
        <BodyText>{bodyText || 'Start adding new records'}</BodyText>
      <Grid item xs={12} sm={12}  md={12} className={classes.center}>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(NoRecords)