import React from 'react';
import styled from 'styled-components';
import { HeaderText } from 'components/atoms/typography/typography';
import { Divider } from '@material-ui/core';
import type { ChildProfileProps } from 'flows_deprecated';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const StyledListItem = styled(ListItem)`
  min-height: 77px;
`;

const patientInfoItems = [
  {
    label: 'Important things to know about me',
    propName: 'thingsToKnow',
  },
  {
    label: 'Allergies',
    propName: 'allergies',
  },
  {
    label: "I'm calmed by",
    propName: 'calmedBy',
  },
  {
    label: "I'm upset by",
    propName: 'upsetBy',
  },
  {
    label: 'I need assistance with',
    propName: 'assistanceWith',
  },
  {
    label: 'Best way to communicate with me',
    propName: 'bestCommunicate',
  },
  {
    label: 'Comfort measures I prefer',
    propName: 'comfortMeasures',
  },
  {
    label: 'My technology',
    propName: 'myTechnology',
  },
];

function ChildProfileInfo(props: { childProfile: ChildProfileProps }) {
  const { childProfile } = props;

  const formatAddress = () => {
    const { address, city, state, zip } = childProfile;
    return `${address}, ${city}, ${state} ${zip}`;
  };

  return (
    <section style={{ padding: '1rem 3rem' }}>
      <HeaderText>Patient Profile</HeaderText>
      <List style={{ marginTop: '1rem' }}>
        <StyledListItem key="address" className="listOne">
          <ListItemText primary="Address" secondary={formatAddress()} />
        </StyledListItem>
        <Divider />
        {patientInfoItems.map((infoItem) => (
          <div key={`div-${infoItem.propName}`}>
            <StyledListItem key={infoItem.propName} className="listTwo">
              <ListItemText
                primary={infoItem.label}
                secondary={childProfile[infoItem.propName]}
              />
            </StyledListItem>
            <Divider />
          </div>
        ))}
      </List>
    </section>
  );
}

export default ChildProfileInfo;
