import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { SubmitButton } from 'components/atoms/buttons';
import { HelpLink, PrimaryHelpLink, Logo } from 'components/atoms/atoms.styled';
import { useDispatch, useSelector } from 'react-redux';
import { NewVerticalList } from 'components/templates';
import { atHomeTheme } from 'global-style';
import Card from '@material-ui/core/Card';
import { BodyText } from 'components/atoms/typography/typography';
import { StyledTextField } from 'components/atoms/fields/index';
import { selectSelf } from 'redux/reselects';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { dashboardRoutes } from 'routes/dashboard';
import { setLoginFlow } from '../../../../redux/actions/auth_actions';
import { useHistory } from 'react-router-dom';
import { CenteredCircularLoader } from 'components/atoms/loaders';
import { enqueueSnack } from 'redux/modules/ui';

function LoginForm(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const self = useSelector(selectSelf);
  const { handleSubmit, control, errors } = useForm();
  const history = useHistory();

  const authenticate = async (loginForm) => {
    setLoading(true);
    try {
      await dispatch(setLoginFlow(loginForm));
    } catch (error) {
      dispatch(
        enqueueSnack({
          message: 'Incorrect username / password combination',
          variant: 'error',
          timeout: 2500,
        })
      );
    }
    setLoading(false);
  };

  const sendToForgot = () => {
    history.push('/login/forgot');
  };

  const sendToSignup = () => {
    history.push('/signup');
  };

  const fieldProps = {
    as: StyledTextField,
    control,
    defaultValue: '',
    fullWidth: true,
  };

  if (!_.isEmpty(self) && self.isAdmin) {
    return <Redirect to={dashboardRoutes.ADMIN_PATIENTS} />;
  }

  return (
    <Card>
      <NewVerticalList className={props.className} center>
        <Logo />
        <form onSubmit={handleSubmit(authenticate)} className={'login-form'}>
          <BodyText style={{ textAlign: 'center', fontWeight: 600 }}>
            Sign in to your account
          </BodyText>
          <Controller
            {...fieldProps}
            name="username"
            label="Email Address"
            variant="outlined"
            error={errors.username ? true : false}
            helperText={errors.username ? errors.username.message : ''}
            rules={{ required: 'Email is required' }}
          />
          <Controller
            {...fieldProps}
            type="password"
            name="password"
            label="Password"
            variant="outlined"
            error={errors.password ? true : false}
            helperText={errors.password ? errors.password.message : ''}
            rules={{ required: 'Please enter your password' }}
          />
          {loading ? (
            <CenteredCircularLoader />
          ) : (
            <SubmitButton fullWidth>Sign In</SubmitButton>
          )}
        </form>
        <BodyText>
          <HelpLink onClick={sendToForgot}>Forgot Password</HelpLink>
        </BodyText>
        <BodyText>No Account?</BodyText>
        <BodyText>
          <PrimaryHelpLink onClick={sendToSignup} color="primary">
            Signup Instead
          </PrimaryHelpLink>
        </BodyText>
      </NewVerticalList>
    </Card>
  );
}

export default styled(LoginForm)`
  width: 400px;
  padding: 20px;
  .login-form {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: ${atHomeTheme.spacing(2)}px;
  }
  .NewLine {
    display: flex;
    margin-bottom: -10px;
  }
  .helpFab {
    margin-top: ${atHomeTheme.spacing(4)}px;
    color: #fff;
  }
  .envelope-icon {
    width: 20px;
    margin-right: ${atHomeTheme.spacing(2)}px;
  }
`;
