// @flow
import { useSnackbar, withSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CloseCircle } from "styled-icons/remix-line";
import type { SnackProps } from "flows_deprecated";
import IconButton from "@material-ui/core/IconButton";
import { atHomeTheme } from "../global-style";

const closeButtonStyle = {
  height: `${atHomeTheme.typography.fontSize}px`,
  marginRight: "5px"
};

function SnackNotifier(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [snackArchive, archiveSnack] = useState([]);
  const snacks: Array<SnackProps> = useSelector(state => state.ui.snackbar);
  const eatSnack = snackKey => closeSnackbar(snackKey);

  useEffect(() => {
    snacks.forEach(
      snack => {
        const { variant, message, timeout, key } = snack;
        const persist = true;
        if (!snackArchive.includes(key)) {
          if (timeout) {
            enqueueSnackbar(message, { variant, key, persist });
            setTimeout(() => eatSnack(key), timeout);
          } else {
            enqueueSnackbar(
              [
                message,
                <IconButton
                  key={key}
                  color="inherit"
                  style={{ marginLeft: "3px" }}
                  onClick={() => eatSnack(key)}
                >
                  <CloseCircle style={closeButtonStyle} />
                </IconButton>
              ],
              { variant, key, persist }
            );
          }
          archiveSnack([...snackArchive, key]);
        }
      },
      [snacks]
    );

    // return () => closeSnackbar();
  });

  return null;
}

export default withSnackbar(SnackNotifier);
