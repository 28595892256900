export const createReducer = (initialState, handlers) => {
  return function reducer(state = initialState, action) {
    const { type, ...payload } = action;
    if (handlers.hasOwnProperty(type)) {
      return handlers[type](state, payload);
    } else {
      return state;
    }
  };
};

// given a list of objects with unique ids (entries), one such object (entry), and the primary key field of the entries
// search for the index of the entry in the list of entries with the same PK as the provided entry argument (match)
// and then replace it with the updated entry. Fast search and replace.
export const findAndReplace = (entries, entry, entryPK) => {
  const nextEntries = [...entries];
  try {
    nextEntries[entries.findIndex(datum => datum[entryPK] === entry[entryPK])] = entry;
  } catch (err) {
    console.error(`error finding ${entryPK} in current DATA state`);
    return entries;
  }
  return nextEntries;
};
