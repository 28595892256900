import React from 'react';
import styled from 'styled-components';
import { HeaderText, TableBodyText } from "../../atoms/typography/typography";
import Grid from '@material-ui/core/Grid';
import { Chip } from '@material-ui/core';
import { VerticalList } from "../../templates";

const labelForCareType = (type) => {
  switch (type) {
    case 'PROCEDURE':
      return 'Supply';
    case 'MEDICATION':
      return 'Medication';
    case 'NUTRITION':
      return 'Nutrition';
    default:
      return '';
  }
}

const CareReportCard = ({ care, ...props }) => {
  return (
    <PaddingContainer>
      <Grid container spacing={4}>
        <Grid item md={12}>
          <HeaderText>{care.name}</HeaderText>
        </Grid>
        <Grid item md={3}>
          <Chip color="secondary" label={care.careRoutineType} />
        </Grid>
        <Grid item md={3}>
          <CenteredGridItem>
            <label htmlFor="" className="capitalize">{labelForCareType(care.careRoutineType)}</label>
            <TableBodyText>{care[labelForCareType(care.careRoutineType).toLowerCase()]?.name}</TableBodyText>
          </CenteredGridItem>
        </Grid>
        <Grid item md={3}>
          <CenteredGridItem>
            <label htmlFor="" className="capitalize">Route</label>
            <TableBodyText>{care?.route?.name}</TableBodyText>
          </CenteredGridItem>
        </Grid>
        <Grid item md={3}>
          <CenteredGridItem>
            <label htmlFor="" className="capitalize">Amount</label>
            <TableBodyText>{care.expectedAmount} {care?.unit?.name ?? care?.supply?.name}</TableBodyText>
          </CenteredGridItem>
        </Grid>
        <Grid item md={12}>
          <TableBodyText>{care.instructions}</TableBodyText>
        </Grid>
      </Grid>
    </PaddingContainer>
  );
}

export default CareReportCard;

const PaddingContainer = styled.div`
  padding: 1rem;
`;

const CenteredGridItem = styled(Grid)`
  ${'' /* text-align: center; */}

  label {
    font-weight: 600;
  }
`;