import React from "react";

export default class ErrorBoundary extends React.Component {
  state = {
    hasError: false
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });

    if (this.props.onError) this.props.onError();
  }

  render() {
    const { className, component: Component = null } = this.props;
    if (this.state.hasError) {
      return <div className={className}>An Error Has Occurred.</div>;
    }

    return Component ? (
      <Component>{this.props.children}</Component>
    ) : className ? (
      <div className={className}>{this.props.children}</div>
    ) : (
      this.props.children
    );
  }
}
