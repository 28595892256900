import { api } from 'api';
import { withErrorHandling } from '../action-utils';
import { caregiverUpdated } from 'redux/modules/admin';

export const fetchCaregiver = (caregiverId) =>
  withErrorHandling(async (dispatch) => {
    const { data: caregiver } = await api.get(`/caregivers/${caregiverId}`);
    dispatch(caregiverUpdated(caregiver));
    return caregiver;
  });

export const deleteCaregiver = (caregiverId) =>
  withErrorHandling(async (dispatch) => {
    const { data: caregiver } = await api.delete(`/caregivers/${caregiverId}`);
    dispatch(caregiverUpdated(caregiver));
    return caregiver;
  });

export const activateCaregiver = (caregiverId) =>
  withErrorHandling(async (dispatch) => {
    const { data: caregiver } = await api.put(
      `/caregivers/${caregiverId}/activate`
    );
    dispatch(caregiverUpdated(caregiver));
    return caregiver;
  });
