import React from 'react';
import { MessageText } from '../../atoms/typography/typography';
import { Form } from 'components/atoms/atoms.styled';
import { DefaultButton, SecondaryButton } from '../../atoms/buttons';
import { Controller, useForm } from 'react-hook-form';
import { TextField, PhoneTextField } from 'components/atoms/fields';
import { SpaceBetweenWrapper, VerticalList } from '../../templates';
import { useDispatch } from 'react-redux';
import { saveAdmin } from 'redux/actions/admin_actions';
import { enqueueSnack, closeGlobalModal } from 'redux/modules/ui';
import { CenteredCircularLoader } from 'components/atoms/loaders';

function CreateAdminForm({ admin, ...props }) {
  const dispatch = useDispatch();
  const { handleSubmit, errors, control } = useForm();
  const [loading, setLoading] = React.useState(false);
  const isNewUser = !admin || !admin.id;

  const submitAdminForm = async (values) => {
    const payload = { ...values, id: admin ? admin.id : 0 };
    setLoading(true);
    const updatedAdmin = await dispatch(saveAdmin(payload));
    setLoading(false);

    if (updatedAdmin) {
      const message = isNewUser ? 'User created' : 'User updated';
      dispatch(
        enqueueSnack({
          message,
          variant: 'success',
          timeout: 2500,
        })
      );
      dispatch(closeGlobalModal());
    }
  };

  return (
    <Form onSubmit={handleSubmit(submitAdminForm)}>
      <VerticalList>
        {isNewUser && (
          <MessageText>
            The admin will receive an email with a link to activate their
            account.
          </MessageText>
        )}
        <Controller
          as={TextField}
          control={control}
          name="firstName"
          label="First Name"
          fullWidth
          type="text"
          variant="outlined"
          defaultValue={admin && admin.firstName ? admin.firstName : ''}
          error={errors.firstName ? true : false}
          helperText={errors.firstName ? errors.firstName.message : ''}
          rules={{ required: 'First name is required' }}
        />
        <Controller
          as={TextField}
          control={control}
          name="lastName"
          label="Last Name"
          fullWidth
          type="text"
          variant="outlined"
          defaultValue={admin && admin.lastName ? admin.lastName : ''}
          error={errors.lastName ? true : false}
          helperText={errors.lastName ? errors.lastName.message : ''}
          rules={{ required: 'Last name is required' }}
        />
        <Controller
          as={PhoneTextField}
          control={control}
          name="phone"
          label="Phone"
          fullWidth
          type="text"
          variant="outlined"
          defaultValue={admin && admin.phone ? admin.phone : ''}
          error={errors.phone ? true : false}
          helperText={errors.phone ? errors.phone.message : ''}
          rules={{
            required: 'Phone is required',
            pattern: {
              value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g,
              message: 'A valid phone number is required',
            },
          }}
        />
        <Controller
          as={TextField}
          control={control}
          name="username"
          label="Email"
          fullWidth
          type="email"
          variant="outlined"
          defaultValue={admin && admin.username ? admin.username : ''}
          error={errors.username ? true : false}
          helperText={errors.username ? errors.username.message : ''}
          rules={{
            required: 'Email is required',
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'A valid email is required',
            },
          }}
        />
        {loading ? (
          <CenteredCircularLoader />
        ) : (
          <SpaceBetweenWrapper>
            <SecondaryButton
              type="button"
              onClick={() => dispatch(closeGlobalModal())}>
              Cancel
            </SecondaryButton>
            <DefaultButton type="submit">Save</DefaultButton>
          </SpaceBetweenWrapper>
        )}
      </VerticalList>
    </Form>
  );
}

export default CreateAdminForm;
