import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Logo } from '../../../atoms/atoms.styled';
import { NewVerticalList } from '../../../templates';
import { SubmitButton } from '../../../atoms/buttons';
import FormLabel from '@material-ui/core/FormLabel';
import Card from '@material-ui/core/Card';
import { atHomeTheme } from 'global-style';
import { StyledTextField } from '../../../atoms/fields/index';
import { activate } from '../../../../redux/actions/auth_actions';
import { CircularProgress } from '@material-ui/core';
import { enqueueSnack } from 'redux/modules/ui';

function LoginForm(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { errors, handleSubmit, control, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const self = useSelector((state) => state.self);
  const fieldProps = {
    as: StyledTextField,
    control,
    defaultValue: '',
    variant: 'outlined',
  };

  const validatePasswordsMatch = (value) => {
    return value === getValues().newPassword ? true : 'Passwords must match';
  };

  const onSubmit = async (data) => {
    const { token } = props.match.params;
    const params = {
      ...data,
      passwordRequestToken: token,
      valid: true,
    };

    setLoading(true);
    const user = await dispatch(activate(params));
    setLoading(false);

    if (user) {
      if (user.isAdmin) {
        history.push('/dashboard');
      } else {
        history.push('/activate/app');
      }
      dispatch(
        enqueueSnack({
          message: 'Account activated',
          variant: 'success',
          timeout: 2500,
        })
      );
    }
  };

  if (self.id && self.isAdmin) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Card>
      <NewVerticalList center className={props.className}>
        <Logo />

        <form onSubmit={handleSubmit(onSubmit)} className={'login-form'}>
          <FormLabel>
            Please create your password to complete your activation.
          </FormLabel>
          <Controller
            {...fieldProps}
            name="newPassword"
            label="Password"
            type="password"
            pattern="/[A-Za-z]{3}/"
            rules={{
              required: '*Password is required',
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                message: `Passwords must be 8 characters long, contain 1 upper case letter,
                          1 lower case lettter, 1 number and 1 special character.`,
              },
            }}
          />
          {errors.newPassword && (
            <StyledError>{errors.newPassword.message}</StyledError>
          )}
          <Controller
            {...fieldProps}
            name="newPasswordConfirmation"
            label="Re-Type Password"
            type="password"
            rules={{
              required: '*Password is required',
              validate: validatePasswordsMatch,
            }}
          />
          {errors.newPasswordConfirmation && (
            <StyledError>{errors.newPasswordConfirmation.message}</StyledError>
          )}
          {loading ? (
            <LoaderContainer>
              <CircularProgress />
            </LoaderContainer>
          ) : (
            <SubmitButton fullWidth>Submit</SubmitButton>
          )}
        </form>
      </NewVerticalList>
    </Card>
  );
}

export default styled(LoginForm)`
  width: 300px;
  padding: 20px;
  text-align: center;
  .login-form {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: ${atHomeTheme.spacing(2)}px;
  }
  .forgot-form {
    display: flex;
    flex-flow: column nowrap;
  }
`;

const StyledError = styled(FormLabel)`
  text-align: left;
  font-weight: 600 !important;
`;

const LoaderContainer = styled('div')`
  text-align: center;
`;
