import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Table from '../../molecules/cruddy-table/cruddy-table';
import { TableBodyText, HeaderText } from "../../atoms/typography/typography";
import { getPatientCareRoutines } from 'redux/actions/patient_actions';
import Grid from '@material-ui/core/Grid';
import DateRangePicker from 'components/molecules/reports/date-range-picker';
import _ from 'lodash';
import CareReportCard from './care-report-card';

const TaskTable = ({ ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [careRoutines, setCareRoutines] = useState([]);
  const patient = useSelector(state => state.patient.patient);

  const columns = [
    {
      title: 'Name',
      field: 'name',
      render: (task) =>
        <TableBodyText>{task.name}</TableBodyText>
    },
    {
      title: 'Type',
      field: 'careRoutineType',
      render: (care) =>
        <TableBodyText>{_.startCase(_.toLower(care.careRoutineType.replace('_', '')))}</TableBodyText>
    },
  ];

  const getCare = async () => {
    if (!patient?.id) {
      return;
    }

    setLoading(true);
    const careRoutines = await dispatch(getPatientCareRoutines(patient.id));
    setLoading(false);
    setCareRoutines(careRoutines);
  }

  const handleRowClick = (event, rowData) => {
    const { id } = rowData;
    history.push(`/dashboard/report/care/${id}`);
  }

  useEffect(() => {
    getCare();
  }, [patient.id]);

  return (
    <div className={props.className}>
      <TableWrapper>
        <Table
          opts={{
            toolbar: true,
            exportButton: true,
            paginationType: 'stepped',
            pageSizeOptions: [10,20,50,100,200],
            pageSize: 20,
          }}
          localization={{
            body: { emptyDataSourceMessage: 'No care routines found for this patient' }
          }}
          components={{
            Toolbar: props => (
              <Grid container justify="space-between" alignItems="center" md={12}>
                <DateFieldWrapper>
                  <HeaderText>Care Procedures</HeaderText>
                </DateFieldWrapper>
              </Grid>
            )
          }}
          cols={columns}
          data={careRoutines}
          isLoading={loading}
          onRowClick={handleRowClick}
        />
      </TableWrapper>
    </div>
  )
}

export default TaskTable;

const TableWrapper = styled('div')`
  padding: 1rem;
`;

const DateFieldWrapper = styled('div')`
  padding: 1rem;
`;