import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ErrorBoundary from '../../error-boundary';
import { StyledAvatar } from '../../atoms/atoms.styled';
import moment from 'moment';
import config from 'config';

function ChildProfiles(props) {
  const childProfile = useSelector((state) => state.patient.patient);

  return (
    <ErrorBoundary>
      <div className={props.className}>
        <div className="child-content">
          <StyledAvatar
            variant={'rounded'}
            className="avtr"
            alt=""
            src={`${config.apiUrl}/image/${childProfile?.photo}`}
          />
        </div>
        <div className="child-info">
          <b className="main">{childProfile.fullName}</b>
          <b className="light">
            {childProfile.dateOfBirth} - Age:{' '}
            {moment().diff(childProfile.dateOfBirth, 'years')} years old
          </b>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default styled(ChildProfiles)`
  display: grid;
  grid-template-columns: 3rem 1fr;
  align-items: center;
  line-height: 18px;
  padding: 2rem;
  .child-info {
    margin-left: 0.8rem;
    b {
      display: block;
      line-height: 1.3;
    }
  }
  & .MuiAvatar-rounded {
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }
  .light {
    color: hsl(210, 3%, 42.7%);
    font-size: 0.875rem;
  }
  .main {
    font-size: 1rem;
    color: hsl(10, 7.5%, 21%);
  }
  .avtr {
    width: 3rem;
    height: 3rem;
    margin-right: 0;
  }
`;
