import {createGlobalStyle} from "styled-components/macro";
import reset from "styled-reset";
import {createMuiTheme} from "@material-ui/core";

export const palette = {
  primary: "#D33939",
  primaryAccent: "#F3ABA5",
  secondary: "#576777",
  secondaryAccent: "#778DA3",
  altHeader: "#000000",
  altBody: "#000000",
  error: "#B31500",
  success: "#1ABB9C",
  white: "#FFFFFF",
  offBlack: "#505050",
};

export const fontFaces = "Roboto, sans-serif";
// export const headerFontFaces = "Slabo 27px, serif";
export const headerFontFaces = "Roboto, sans-serif";

export const fontSizes = {
  default: 14
};

const GlobalStyle = createGlobalStyle`
  ${reset}
  html,
  body,
  #root {
    font-family: ${fontFaces};
    font-size: ${fontSizes.default};
    height: 100%;
    width: 100%;
    min-width: 100%;
    padding: 0;
   
  }
  
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

`;

export default GlobalStyle;

export const atHomeTheme = createMuiTheme({
  palette: {
    primary: {
      main: palette.primary
    },
    secondary: {
      main: palette.secondary
    },
    error: {
      main: palette.error
    },
    success: {
      main: palette.success
    },
    white: {
      main: palette.white,
    },
    offBlack: {
      main: palette.offBlack,
    },
    contrastThreshold: 3
    // tonalOffset: 0.9
  },
  typography: {
    fontFamily: fontFaces,
    fontSize: fontSizes.default,
    htmlFontSize: fontSizes.default,
    h1: {
      fontFamily: headerFontFaces,
      textTransform: "capitalize!important"
    },
    h2: {
      fontFamily: headerFontFaces,
      textTransform: "capitalize!important"
    },
    h3: {
      fontFamily: headerFontFaces,
      textTransform: "capitalize!important"
    },
    h4: {
      fontFamily: headerFontFaces
    },
    h5: {
      fontFamily: headerFontFaces
    },
    h6: {
      fontFamily: headerFontFaces
    },
    button: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: 1.5,
      textTransform: "capitalize",
      color: palette.offBlack,
    }
  },
  spacing: 4,
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: ".75rem",
        color: palette.secondary,
        lineHeight:"16px",
        '&$focused': {
          color: palette.secondary
      }
      }
    },
    MuiButton: {
      root: {
        color: palette.offBlack,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: palette.white
      }
    },
    MuiOutlinedInput: {
      root: {
          position: 'relative',
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
              borderColor: palette.secondary,
              '@media (hover: none)': {
                  borderColor: palette.secondary,
              },
          },
          '&$focused $notchedOutline': {
              borderColor: palette.secondary,
              borderWidth: 1,
          },
      },
    },
    MuiDropzoneArea: {
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${palette.secondary}`,
        borderRadius: '5px',
      }
    }
  }
});

console.info("computed-theme:", atHomeTheme);
