import styled from "styled-components";
import logoSource from "assets/logoHome.png";
import { atHomeTheme } from "../../global-style";
import Avatar from "@material-ui/core/Avatar";

export const Input = styled.input`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #666;
  padding: 10px 15px;
  //margin-bottom: 10px;
  margin-top: 10px;
  font-size: 14px;
  color: #73879c;
  &&:focus{
  outline:red;
  }
`;

export const Dropdown = styled.select`
  width: 100%;
  height: 40px;
  margin: 0 0 10px 0;
`;

export const HelpLink = styled.a`
  display: block;
  text-align: center;
  color: ${atHomeTheme.palette.secondary.main};
  :hover {
    color: ${atHomeTheme.palette.secondary.light};
    cursor: pointer;
  }
`;

export const PrimaryHelpLink = styled.a`
  display: block;
  text-align: center;
  color: ${atHomeTheme.palette.primary.main};
  :hover {
    color: ${atHomeTheme.palette.primary.light};
    cursor: pointer;
  }
`;

export const Logo = styled.img.attrs(props => ({
  src: logoSource,
  alt: "@Home Logo",
  ...props
}))`
  height: 80px;
  width: auto;
`;

export const Form = styled.form`
  background: ${atHomeTheme.palette.background.paper};
  padding: ${atHomeTheme.spacing(5)}px ${atHomeTheme.spacing(6)}px;
  width: 100%;
  height: 100%;

  //min-width: 600px;
  //min-height: 100%;
`;

export const StyledAvatar = styled(Avatar)`
  width: ${atHomeTheme.spacing(2)}px;
  height: ${atHomeTheme.spacing(2)}px;
  
  img {
    object-fit: cover;
  }
`;

export const AdminHeader = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: space-between;
`;
