import React from 'react';
import { useHistory } from 'react-router-dom';
import Table from 'components/molecules/cruddy-table/cruddy-table';
import { TableBodyText } from '../../atoms/typography/typography';
import { StyledAvatar } from 'components/atoms/atoms.styled';
import config from 'config';
import { api } from 'api';
import { CenteredCircularLoader } from 'components/atoms/loaders';

export default ({ caregiver, editAction, ...props }) => {
  const history = useHistory();
  const [patients, setPatients] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const columns = [
    {
      title: '',
      field: 'photo',
      render: (patient) => (
        <StyledAvatar
          src={`${config.apiUrl}/image/${patient.photo}`}
          alt={patient.fullName}
        />
      ),
      width: 100,
    },
    {
      title: 'Name',
      field: 'fullName',
      render: (patient) => <TableBodyText>{patient.fullName}</TableBodyText>,
    },
    {
      title: 'DOB',
      field: 'dateOfBirth',
      render: (patient) => <TableBodyText>{patient.dateOfBirth}</TableBodyText>,
    },
    {
      title: 'Gender',
      field: 'gender',
      render: (patient) => <TableBodyText>{patient.gender}</TableBodyText>,
    },
    {
      title: 'Is Primary',
      field: 'isPrimary',
      render: (patient) => (
        <TableBodyText>
          {patient.primaryCaregiver.id === caregiver.id
            ? 'Primary'
            : 'Secondary'}
        </TableBodyText>
      ),
    },
  ];

  const fetchPatients = async () => {
    if (!caregiver.patientIds || !caregiver.patientIds.length) {
      return;
    }

    let queryString = `?ids=${caregiver.patientIds.join(',')}`;
    setLoading(true);
    try {
      const { data: patients } = await api.get(`/patients/ids${queryString}`);
      setPatients(patients);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchPatients();
  }, [caregiver]);

  return loading ? (
    <CenteredCircularLoader />
  ) : (
    <Table
      title="Patients"
      style={{ boxShadow: 'none', padding: '2rem', backgroundColor: '#EEE' }}
      data={patients}
      cols={columns}
      opts={{
        header: false,
        toolbar: true,
        showTitle: true,
        paging: false,
        size: 'small',
        rowStyle: {
          backgroundColor: '#EEE',
        },
      }}
      localization={{
        body: { emptyDataSourceMessage: 'No patients for this caregiver' },
        header: {
          actions: '',
        },
      }}
      onRowClick={(event, rowData, togglePanel) =>
        history.push(`/dashboard/admin/patient/${rowData.id}`)
      }
    />
  );
};
