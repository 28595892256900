import React from 'react';
import styled from 'styled-components';
import SideBarNav from 'components/organisms/side-bar-nav/side-bar-nav';
import { atHomeTheme } from 'global-style';
import { Drawer } from '@material-ui/core';
import ProtectedRoute from '../../components/protected-route';
import AdminRouter from '../admin';

export const dashboardRoutes = {
  ADMIN: '/dashboard/admin',
  ADMIN_PATIENTS: '/dashboard/admin/patients',
  ADMIN_CAREGIVERS: '/dashboard/admin/caregivers',
  ADMIN_USERS: '/dashboard/admin/users',
  ADMIN_PATIENT: '/dashboard/admin/patient/:patientId',
  ADMIN_REPORT: '/dashboard/admin/patient/:patientId/report',
};

const drawerWidth = 240;

function Dashboard(props) {
  return (
    <div className={props.className}>
      <Drawer variant="permanent" anchor="left" open={true} className="drawer">
        <SideBarNav dashboardRoutes={dashboardRoutes} classname="sidebar" />
      </Drawer>
      <main className="main-content">
        <ProtectedRoute path={dashboardRoutes.ADMIN} component={AdminRouter} />
      </main>
    </div>
  );
}

export default styled(Dashboard)`
  display: flex;
  && .MuiDrawer-paper {
    width: ${drawerWidth}px !important;
    overflow-x: hidden;
  }
  .app-bar {
    z-index: ${atHomeTheme.zIndex.drawer + 1};
    .tool-bar {
      display: none !important;
      justify-content: space-between;
      > .menu-box {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: auto;
        padding-right: ${atHomeTheme.spacing(4)}px;
      }
      .logo-text {
        color: white;
        font-family: 'Slabo 27px', cursive;
      }
      .logo-box {
        padding: ${atHomeTheme.spacing(1)}px;
        margin-right: ${atHomeTheme.spacing(1)}px;
        img {
          height: 55px;
        }
      }
    }
  }
  .drawer {
    width: ${drawerWidth}px;
    flex-shrink: 0;
    && .MuiDrawer-paper {
      width: ${drawerWidth}px !important;
    }
  }
  .main-content {
    flex-grow: 1;
    padding: ${atHomeTheme.spacing(3)};
  }
  .medical-book-icon {
    margin-right: 5px;
    height: 1.1rem;
  }

  .toolbar {
    ${atHomeTheme.mixins.toolbar}
  }
`;
