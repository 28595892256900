import { combineReducers } from 'redux';
import self, {getSetSelf} from './self';
import patient from './patient';
import patients from './patients';
import ui from './ui';
import report from './report';
import admin from './admin';

const appReducer = combineReducers({
  patient,
  patients,
  self,
  ui,
  report,
  admin,
});

export default (state, action) => {
  if (action.type === 'PURGE_STORE') state = undefined;
  return appReducer(state, action);
};
