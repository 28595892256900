// @flow
import { createReducer } from 'redux/reducer-utils';
import _ from 'lodash';
export const SET_USERS = 'SET_USERS';
export const SET_CAREGIVERS = 'SET_CAREGIVERS';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const CREATE_ADMIN = 'CREATE_ADMIN';
export const UPDATE_CAREGIVER = 'UPDATE_CAREGIVER';
export const CREATE_CAREGIVER = 'CREATE_CAREGIVER';

const defaultState = {
  users: [],
  caregivers: [],
};

export default createReducer((defaultState: Array), {
  [SET_USERS]: (state, { users = [] }) => {
    return {
      ...state,
      users
    }
  },
  [SET_CAREGIVERS]: (state, { caregivers = [] }) => {
    return {
      ...state,
      caregivers,
    };
  },
  [UPDATE_ADMIN]: (state, { admin = { id: 0 } }) => {
    const adminIndex = _.findIndex(state.users, { id: admin.id });

    if (adminIndex >= 0) {
      const admins = [...state.users];
      admins.splice(adminIndex, 1, admin);

      return {
        ...state,
        users: admins,
      };
    }
    
    return state;
  },
  [CREATE_ADMIN]: (state, { admin = {} }) => {
    const admins = [...state.users, admin];
    return {
      ...state,
      users: admins,
    };
  },
  [UPDATE_CAREGIVER]: (state, { caregiver = { id: 0 } }) => {
    const caregiverIndex = _.findIndex(state.caregivers, { id: caregiver.id });
    
    if (caregiverIndex >= 0) {
      const caregivers = [...state.caregivers];
      caregivers.splice(caregiverIndex, 1, caregiver);

      return {
        ...state,
        caregivers,
      };
    }

    return state;
  },
  [CREATE_CAREGIVER]: (state, { caregiver = {} }) => {
    const caregivers = [...state.caregivers, caregiver];
    return {
      ...state,
      caregivers,
    };
  }
});

// synchronous action creators
export const setAdminUsers = (users) => {
  return {
    type: SET_USERS,
    users,
  };
};

export const setCaregivers = (caregivers) => {
  return {
    type: SET_CAREGIVERS,
    caregivers,
  };
};

export const adminUpdated = (admin) => ({
  type: UPDATE_ADMIN,
  admin,
});

export const adminCreated = (admin) => ({
  type: CREATE_ADMIN,
  admin,
});

export const caregiverUpdated = (caregiver) => ({
  type: UPDATE_CAREGIVER,
  caregiver,
});

export const caregiverCreated = (caregiver) => ({
  type: CREATE_CAREGIVER,
  caregiver,
});
