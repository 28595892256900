import React from 'react';
import { useDispatch } from 'react-redux';
import Table from 'components/molecules/cruddy-table/cruddy-table';
import { TableBodyText } from '../../atoms/typography/typography';
import { StyledAvatar } from 'components/atoms/atoms.styled';
import { Delete, LockOpen } from '@material-ui/icons';
import config from 'config';
import CaregiverPatientTable from 'components/organisms/caregiver-patient-table';
import {
  deleteCaregiver,
  activateCaregiver,
} from 'redux/actions/caregiver_actions';
import { enqueueSnack } from 'redux/modules/ui';
import _ from 'lodash';

export default ({ caregivers, ...props }) => {
  const dispatch = useDispatch();
  const caregiverData = caregivers.map(({ createdAt, ...rest }) => ({
    ...rest,
    createdAt: new Date(createdAt),
  }));
  const columns = [
    {
      title: '',
      field: 'photo',
      render: (caregiver) => (
        <StyledAvatar
          src={`${config.apiUrl}/image/${caregiver.photo}`}
          alt={caregiver.fullName}
        />
      ),
      width: 100,
      filtering: false,
      export: false,
    },
    {
      title: 'Name',
      field: 'fullName',
      render: (caregiver) => (
        <TableBodyText>{caregiver.fullName}</TableBodyText>
      ),
    },
    {
      title: 'Email',
      field: 'username',
      render: (caregiver) => (
        <TableBodyText>{caregiver.username}</TableBodyText>
      ),
    },
    {
      title: 'Phone',
      field: 'phone',
      render: (caregiver) => <TableBodyText>{caregiver.phone}</TableBodyText>,
    },
    {
      title: 'Communication Method',
      field: 'communicationMethod',
      render: (caregiver) => (
        <TableBodyText>
          {_.startCase(
            _.toLower(caregiver.communicationMethod?.replace('_', ' '))
          )}
        </TableBodyText>
      ),
      lookup: {
        PHONE: 'Phone',
        EMAIL: 'Email',
        TEXT: 'Text',
      },
    },
    {
      title: 'Type',
      field: 'primaryCaregiver',
      render: ({ primaryCaregiver }) => (
        <TableBodyText>
          {_.startCase(_.toLower(primaryCaregiver ? 'Primary' : 'Secondary'))}
        </TableBodyText>
      ),
    },
    {
      title: 'Account Created Date',
      field: 'createdAt',
      type: 'date',
    },
    {
      title: 'Status',
      field: 'status',
      render: (caregiver) => (
        <TableBodyText>
          {_.startCase(_.toLower(caregiver.status.replace('_', ' ')))}
        </TableBodyText>
      ),
      lookup: {
        ACTIVE: 'Active',
        AWAITING_ACTIVATION: 'Awaiting Activation',
        DELETED: 'Deleted',
      },
    },
  ];

  const disableCaregiver = async (caregiver) => {
    const updatedCaregiver = await dispatch(deleteCaregiver(caregiver.id));

    if (updatedCaregiver) {
      dispatch(
        enqueueSnack({
          message: 'Caregiver disabled',
          variant: 'success',
          timeout: 2500,
        })
      );
    }
  };

  const enableCaregiver = async (caregiver) => {
    const updatedCaregiver = await dispatch(activateCaregiver(caregiver.id));

    if (updatedCaregiver) {
      dispatch(
        enqueueSnack({
          message: 'Caregiver activated',
          variant: 'success',
          timeout: 2500,
        })
      );
    }
  };

  return (
    <Table
      title="Caregivers"
      style={{ boxShadow: 'none' }}
      data={caregiverData}
      cols={columns}
      actions={[
        (rowData) => ({
          icon: rowData.status === 'ACTIVE' ? Delete : LockOpen,
          tooltip:
            rowData.status === 'ACTIVE'
              ? 'Disable Careigiver'
              : 'Enable Caregiver',
          onClick:
            rowData.status === 'ACTIVE'
              ? () => disableCaregiver(rowData)
              : () => enableCaregiver(rowData),
        }),
      ]}
      opts={{
        toolbar: true,
        showTitle: false,
        paging: true,
        search: true,
        paginationType: 'stepped',
        pageSizeOptions: [10, 20, 50, 100, 200, 500],
        pageSize: 20,
        filtering: true,
        exportButton: true,
        exportFileName: 'Caregiver Export',
      }}
      localization={{
        body: { emptyDataSourceMessage: 'No caregivers found' },
        header: {
          actions: '',
        },
      }}
      detailPanel={[
        {
          tooltip: 'Patients',
          render: (rowData) => {
            return (
              <CaregiverPatientTable
                patients={rowData.patients}
                caregiver={rowData}
              />
            );
          },
        },
      ]}
    />
  );
};
