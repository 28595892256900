import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';

export const CenteredCircularLoader = ({ ...props }) => {
  return (
    <CenteredDiv>
      <CircularProgress />
    </CenteredDiv>
  )
}

const CenteredDiv = styled.div`
  text-align: center;
`;

export const TableSkeleton = ({ ...props }) => {
  return (
    <>
      <MarginSkeleton variant="rect" animation="wave" height={75} />
      <MarginSkeleton variant="rect" height={75} />
      <MarginSkeleton variant="rect" animation="wave" height={75} />
      <MarginSkeleton variant="rect" height={75} />
      <MarginSkeleton variant="rect" animation="wave" height={75} />
      <MarginSkeleton variant="rect" height={75} />
      <MarginSkeleton variant="rect" animation="wave" height={75} />
      <MarginSkeleton variant="rect" height={75} />
      <MarginSkeleton variant="rect" animation="wave" height={75} />
      <MarginSkeleton variant="rect" height={75} />
      <MarginSkeleton variant="rect" animation="wave" height={75} />
      <MarginSkeleton variant="rect" height={75} />
    </>
  );
}

const MarginSkeleton = styled(Skeleton)`
  margin: .5rem 0;
`;