import React from 'react';
import GlobalSnackbar from 'components/global-snackbar';
import GlobalModal from 'components/global-modal';
import { SnackbarProvider } from 'notistack';
import store from 'redux/store';
import { Provider } from 'react-redux';
import GlobalStyle, { atHomeTheme } from './global-style';
import { MuiThemeProvider } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import { setAuthHeader, clearAuthHeader } from './api';
import { getSelf } from './redux/actions/auth_actions';
import AppLoading from 'components/app-loading';

let base = process.env.REACT_APP_ROUTER_BASENAME_DEV;

if (process.env.REACT_APP_ENV === 'prod') {
  base = process.env.REACT_APP_ROUTER_BASENAME_PROD;
}

const authToken = sessionStorage.getItem('token');

if (authToken) {
  setAuthHeader(authToken);
} else {
  clearAuthHeader();
}

function App(props) {
  const [loading, setLoading] = React.useState(true);

  const startupAuth = async () => {
    setLoading(true);
    await store.dispatch(getSelf());
    setLoading(false);
  };

  React.useEffect(() => {
    startupAuth();
  }, []);

  return (
    <>
      <GlobalStyle />
      <Provider store={store}>
        <MuiThemeProvider theme={atHomeTheme}>
          <GlobalModal />
          {loading ? (
            <AppLoading />
          ) : (
            <BrowserRouter basename={base}>{props.children}</BrowserRouter>
          )}
          <SnackbarProvider maxSnack={3}>
            <GlobalSnackbar />
          </SnackbarProvider>
        </MuiThemeProvider>
      </Provider>
    </>
  );
}

export default App;
