// @flow
import { createReducer } from 'redux/reducer-utils';
import type { UserResponse } from 'data-flows';

export const SET_SELF = 'SET_SELF';
export const LOGOUT = 'LOGOUT';
export const UPDATE_SELF = 'UPDATE_SELF';

const defaultState = {};

export default createReducer((defaultState: UserResponse), {
  [SET_SELF]: (state, self) => {
    const isAdmin = !!self.user.authorities.find(
      (a) => a.role === 'ROLE_ADMIN'
    );
    return {
      ...state,
      ...self.user,
      isAdmin,
    };
  },
  [UPDATE_SELF]: (state, self) => {
    return {
      ...state,
      ...self.data,
    };
  },
  [LOGOUT]: () => {
    return {};
  },
});

// synchronous action creators
export const setSelf = (self: UserResponse) => {
  return {
    type: SET_SELF,
    ...self,
  };
};
